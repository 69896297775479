import { useRouteError } from 'react-router-dom'
import { useLocalization } from '../../providers/LocalizationProvider';
import MainLayout from '../../layouts/main';
import { useEffect, useState } from 'react';
import { getOnlineProfile } from '../../../assets/api';

const ErrorPage: React.FC = () => {

  const error: unknown = useRouteError();
  const { getTranslation, getTranslationWithParameters } = useLocalization();
  const [ user, setUser ] = useState();

  useEffect(() => {
    //
    const loadUserData = async () => {
      //
      try {
        //
        let userResponse = await getOnlineProfile();
        let userJson = userResponse?.status === 200 && userResponse?.headers?.get('content-type') === "application/json" ? await userResponse.json() : null;
        setUser(userJson);
      } catch (err) {
        // silently ignore errors when fetching user profile
      }
    }
    loadUserData();
  }, [])

  return (
    <MainLayout user={user}>
      <div
        id='error-page'
        className='flex flex-col gap-8 justify-center items-center h-full grow text-gray-text my-12'
      >
        <h1 className='text-4xl'>{getTranslation("error.errorTitle")}</h1>
        <p
          className='text-2xl text-center'
          dangerouslySetInnerHTML={{__html: getTranslation("error.error_description_1")}}
        />
        <p
          className='text-2xl text-center'
          dangerouslySetInnerHTML={{__html: getTranslationWithParameters({
            key: "error.error_description_2",
            parameters: {
              contactLink: "<a href='https://support.tekla.com/contact-us' target=\"_blank\" className='text-trimble-blue hover:text-trimble-orange'>https://support.tekla.com/contact-us</a>"
            }
          })}}
        />
        <p
          className='text-2xl text-center'
          dangerouslySetInnerHTML={{__html: getTranslationWithParameters({
            key: "error.error_description_3",
            parameters: {
              loginLinkStart: "<a href='/'>",
              linkEnd: "</a>"
            }
          })}}
        />
        <div className='text-slate-400'>
          <i className='flex flex-row space-x-2'>
            <div>{(error as {status?: number})?.status}</div>
            <div>
              {
              (error as Error)?.message ||
              (error as { statusText?: string })?.statusText
              }
            </div>
          </i>
        </div>
      </div>
    </MainLayout>
  )
}

export default ErrorPage;