import { ChangeEventHandler } from "react";
import { ObjectValidationError } from "../../../model/ObjectValidationError";
import { ReactElement } from "react";

export function CustomCheckbox(
    {
      id,
      testdataId,
      name, 
      label, 
      value, 
      setValue, 
      error 
    }: { 
      id?: string,
      testdataId?: string,
      name: string, 
      label: string | ReactElement, 
      value?: any, 
      setValue: ChangeEventHandler<HTMLInputElement>, 
      error?:  ObjectValidationError 
    }) {

    return (
        <div className='whitespace-break-spaces space-y-4 text-xs '>
          <div className='flex flex-row space-x-4'>
            <input
              id={id}
              testdata-id={testdataId}
              name={name}
              type='checkbox'
              className={`scale-150 accent-trimble-blue`}
              checked={value}
              onChange={setValue}
            />
            <div className={`${error ? "text-red-500" : "text-gray-500"}`}>{label}</div>
          </div>
        {
            error ? 
            <div className="my-2 text-red-600">{error.error_description}</div>
            : null
        }
        </div>
    )
}

export default CustomCheckbox;