export let specialization = [
  'ACCOUNTING',
  'ARCHITECT',
  'CLEVEL',
  'DETAILER',
  'ENGINEER_CIVIL',
  'ENGINEER_MEP',
  'ENGINEER_STRUCTURAL',
  'ENGINEER_SITE',
  'ESTIMATOR',
  'FABRICATOR',
  'HUMAN_RESOURCES',
  'FACILITIES_MANAGEMENT',
  'INFORMATION_SYSTEMS_AND_TECHNOLOGY',
  'BIM_MANAGER',
  'MARKETING_PROFESSIONAL',
  'PLANT_PRODUCTION_MANAGER',
  'PURCHASING_PROCUREMENT',
  'PROJECT_MANAGEMENT',
  'SURVEYOR',
]