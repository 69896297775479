import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { accountRoutes } from '../../../router';
import { User } from '../../../model/User';
import { useLocalization } from '../../providers/LocalizationProvider';
import { OrganizationGroup } from '../../../model/OrganizationGroup';
import { OrganizationRole } from '../../../model/OrganizationRole';

export function SideNavigation({ user, employeesGroup, roles }: { user: User, employeesGroup: OrganizationGroup, roles: OrganizationRole[] }) {

  const navigate = useNavigate();
  const location = useLocation();
  const { getTranslation } = useLocalization();
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const onSideNavItemClicked = (event: any) => {
    if (event.detail && event.detail.selected) {
      navigate('/' + event.detail.id);
    }
  };

  /**
   * Only show activate licenses page to educational users.
   */
  let navItems = accountRoutes
    .filter(i => user?.type === "EDUCATIONAL" || (user?.type === "PROFESSIONAL" && i.id !== "activate-licenses"))
    .map(i => {
      return {
        ...i,
        onSideNavItemClicked,
        selected: location.pathname === (i.url)
      }
    })

  /**
   * Disables links if profile is incomplete.
   */
  const clickHandler = (e: React.MouseEvent) => {
    if (!user.isProfileCompleted) e.preventDefault();
  }

  /**
   * Parses current search params and returns those that should be kept during navigation.
   * For now only returnUrl and continueTo parameter should be kept.
   */
  const parseAndReturnHeldSearchParams = () => {
    //
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.size > 0) {
      return searchParams.toString();
    } else {
      return "";
    }
  }

  const userHasAccessToAdminTool = () => {
    //
    let adminRoleInOrganization = roles?.filter((role) => (role.name === "Admin" || role.name === "ContractManager"));
    return adminRoleInOrganization?.length > 0;
  }

  return (
    <div id='side-nav-container' className={`border-r-2 h-full shrink-0 max-w-[260px] ${isOpen ? 'w-full' : 'w-20'}`}>
      <div id='side-nav-header' className={`flex flex-row px-2 py-4 ${isOpen ? 'justify-between' : 'justify-center'} space-x-2`}>
        {isOpen ?
          <>
            <div id='side-nav-name' className='text-gray-text text-md font-semibold truncate'>{user?.firstName + ' ' + user?.lastName}</div>
            <div className='cursor-pointer' onClick={() => setIsOpen(false)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="mi-solid mi-chevron-left" viewBox="0 0 24 24">
                <path d="M14.71 6.71a.996.996 0 0 0-1.41 0L8.71 11.3a.996.996 0 0 0 0 1.41l4.59 4.59a.996.996 0 1 0 1.41-1.41L10.83 12l3.88-3.88c.39-.39.38-1.03 0-1.41Z" />
              </svg>
            </div>
          </>
          :
          <div className='cursor-pointer' onClick={() => setIsOpen(true)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="mi-solid mi-chevron-right" viewBox="0 0 24 24">
              <path d="M9.29 6.71a.996.996 0 0 0 0 1.41L13.17 12l-3.88 3.88a.996.996 0 1 0 1.41 1.41l4.59-4.59a.996.996 0 0 0 0-1.41L10.7 6.7c-.38-.38-1.02-.38-1.41.01Z" />
            </svg>
          </div>}
      </div>
      <div id='side-nav-items' className='text-sm pb-12'>
        {
          navItems.map((obj, i) =>
            <NavLink
              testdata-id={`navigation-tab-${obj.id}`}
              key={'nav-' + i}
              to={{
                pathname: obj.url,
                search: parseAndReturnHeldSearchParams()
              }}
              relative="path"
              onClick={clickHandler}
            >
              <div
                id='side-nav-item'
                className={`hover:bg-gray-300/50 hover:text-black px-6 py-4 ${obj.selected ? 'bg-trimble-blue text-white' : 'bg-white text-black'} ${user.isProfileCompleted ? "cursor-pointer" : "cursor-not-allowed"}`}
              >
                {isOpen ? getTranslation(obj.label) : obj.icon}
              </div>
            </NavLink>
          )}
        {
          userHasAccessToAdminTool() ?
            <a
              testdata-id={`navigation-tab-admin-tool`}
              className={`hover:bg-gray-300/50 hover:text-black px-6 py-4 bg-white text-black flex flex-row space-x-2 ${user.isProfileCompleted ? "cursor-pointer" : "cursor-not-allowed"}`}
              href={window.ENV.ADMIN_TOOL_URL}
              rel="noopener"
              aria-label='Admin Tool'
              onClick={clickHandler}
            >
              {isOpen ?
                "Tekla Online Admin Tool"
                :
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="mi-solid mi-launch" viewBox="0 0 24 24">
                  <path d="M19.78 19.78H4.22V4.22h6.67c.61 0 1.11-.5 1.11-1.11S11.5 2 10.89 2H3c-.55 0-1 .45-1 1v18c0 .55.45 1 1 1h18c.55 0 1-.45 1-1v-7.89c0-.61-.5-1.11-1.11-1.11s-1.11.5-1.11 1.11zM14.22 3.11c0 .61.5 1.11 1.11 1.11h2.88L8.07 14.36c-.43.43-.43 1.13 0 1.57.43.43 1.13.43 1.57 0L19.78 5.79v2.88c0 .61.5 1.11 1.11 1.11S22 9.28 22 8.67V3c0-.55-.45-1-1-1h-5.67c-.61 0-1.11.5-1.11 1.11" />
                </svg>
              }
            </a>
            : null
        }
      </div>
    </div>
  );
}

export default SideNavigation;