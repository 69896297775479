import { useLoaderData, useRouteLoaderData } from 'react-router-dom';
import { User } from '../../../model/User';
import ProfileColumnTid from './tid';
import ProfileColumnProfile from './profile';
import { OrganizationGroup } from '../../../model/OrganizationGroup';
import { useLocalization } from '../../providers/LocalizationProvider';

export function ProfilePage() {

  let data = useRouteLoaderData('root') as any;
  let user = data?.user as User;
  let employeesGroup = useLoaderData() as OrganizationGroup;
  const {getTranslation} = useLocalization();

  return (
    <div
      id='profile-page'
      className='flex flex-col gap-8'
    >
      {
        user?.type ? 
        <h1
          className='text-2xl text-gray-500'
        >
          {getTranslation("account.profile.myProfileTitle")}
        </h1>
        :
        <div testdata-id="profile-new-user-notification" className='mt-2 border-2 border-l-[6px] border-trimble-blue text-trimble-blue font-bold py-4 px-2 w-full'>
          {getTranslation("account.profile.newUserNotification")}
        </div>
      }
      <div
        id='profile-page'
        className='flex flex-wrap'
      >
        <ProfileColumnProfile user={user} employeesGroup={employeesGroup} newUser={user?.type === undefined || user?.type === null}/>
        <ProfileColumnTid user={user} />
      </div>
    </div>
    )
}

export default ProfilePage;