// Query parameters
export let PARAMETER_NAME_CONTINUE_TO = "continueTo";
export let PARAMETER_NAME_RETURN_TO = "returnUrl";
export let PARAMETER_NAME_REDIRECT_URI = "redirect_uri";
export let PARAMETER_NAME_ENCODED_PARAMETERS = "encodedParams";
export let PARAMETER_NAME_REQUEST_TO_JOIN = "requestToJoin";
export let PARAMETER_NAME_INVITATION_CODE = "invitation";
export let PARAMETER_NAME_AUTHZ_HANDLER = "authzHandler";
export let PARAMETER_NAME_SKIP_OPTIONAL_REDIRECTS = "skipOptionalRedirects";

// Login paths
export let PARAMETER_NAME_LOGIN_PATH = "/login/index.html?startOauth2Upstream";
export let PARAMETER_NAME_LOGOUT_PATH = "/logout/?triggerSingleSignout=true";