import { Trial } from '../../../model/Trial';
import CustomButton from '../../custom/CustomButton';
import CustomModal from '../../custom/CustomModal';
import { useEffect, useState } from 'react';
import { useNotification } from '../../providers/NotificationProvider';
import { useLoaderData, useNavigate, useRouteLoaderData } from 'react-router-dom';
import { User } from '../../../model/User';
import CustomCheckbox from '../../custom/CustomCheckbox';
import { startTrial as startTrialOperation } from '../../../assets/api';
import { useLocalization } from '../../providers/LocalizationProvider';

export function ActivateLicenses() {

  let loaderData = useLoaderData() as any;
  const trials =  loaderData.licenses as Array<Trial>;

  let data = useRouteLoaderData('root') as any;
  let user = data?.user as User;
  const navigate = useNavigate();
  const { getTranslation } = useLocalization();
  const { sendNotification } = useNotification();
  const [ selectedTrial, setSelectedTrial ] = useState<Trial | undefined>();
  const [ isModalVisible, setIsModalVisible ] = useState<boolean>(false);
  const [ termsAccepted, setTermsAccepted ] = useState<boolean>(false);

  useEffect(() => {
    if (user?.type !== "EDUCATIONAL") {
      navigate('/account/');
    }
  }, [])

  const selectTrial = (trial: Trial) => {
    setSelectedTrial(trial);
    setIsModalVisible(true);
  }

  const closeModal = () => {
    setIsModalVisible(false);
    setTermsAccepted(false);
  }

  const startTrial = async () => {
    setIsModalVisible(false);
    //
    try {
        if (selectedTrial != null) {
          //
          let response = await startTrialOperation(selectedTrial.id);
          if (response.status === 200) {
            sendNotification(getTranslation("account.activateLicenses.licensesModal.notifications.startSuccess"), 'success');
            navigate('.', { replace: true });
            setTermsAccepted(false);
          } else {
            let errorDescription = (await response.json() as any)?.error_description;
            sendNotification(errorDescription ? errorDescription : getTranslation("account.activateLicenses.licensesModal.notifications.startFailed"), "error");
          }
        }
    } catch (err) {
        sendNotification(getTranslation("account.activateLicenses.licensesModal.notifications.startFailed"), "error");
    }
  }

  return (
    <div
      id='activate-licenses-page'
      className='w-full flex flex-col space-y-8'
    >
      <div
        id='landing-col-left'
        className='flex flex-col gap-6 justify-center h-full w-full'
      >
          <h1
            className='text-2xl text-gray-500'
          >
            {getTranslation("account.activateLicenses.activateLicensesTitle")}
          </h1>
          <CustomModal
            testdataId='activate-licenses-modal'
            isVisible={isModalVisible}
            closeModal={() => setIsModalVisible(false)}
            label={selectedTrial?.name}
            description={
              <div className='flex flex-col space-y-4'>
                <div>
                  {
                    selectedTrial ? 
                    <div dangerouslySetInnerHTML={{__html: selectedTrial?.termsAndConditions}} />
                    : null
                  }
                </div>
                <div className='flex flex-row space-x-2'>
                  <CustomCheckbox
                    testdataId="activate-licenses-modal-terms-checkbox"
                    name='termsAndConditions'
                    label={getTranslation("account.activateLicenses.licensesModal.termsAndConditions")}
                    value={termsAccepted}
                    setValue={() => setTermsAccepted(!termsAccepted)}
                  />
                </div>
              </div>
            }
            buttons={
              [
                <CustomButton
                  testdataId={`activate-licenses-modal-cancel-button`}
                  label={getTranslation("account.activateLicenses.licensesModal.buttons.cancel")}
                  type='secondary'
                  onClick={closeModal}
                />,
                <CustomButton
                  testdataId={`activate-licenses-modal-start-button`}
                  label={getTranslation("account.activateLicenses.licensesModal.buttons.start")}
                  type='primary'
                  onClick={startTrial}
                  disabled={!termsAccepted}
                />
              ]
            }
          />
          <table testdata-id='activate-licenses-table' className='table-auto border border-gray-600 md:w-[720px]'>
            <thead className=''>
              <tr>
                <th className='border border-slate-400 p-2 bg-gray-background font-normal text-left pl-4 text-gray-600'>{getTranslation("account.activateLicenses.licensesTable.columns.name")}</th>
                <th className='border border-slate-400 p-2 bg-gray-background font-normal text-left pl-4 text-gray-600'>{getTranslation("account.activateLicenses.licensesTable.columns.daysLeft")}</th>
                <th className='border border-slate-400 p-2 bg-gray-background font-normal text-left pl-4 text-gray-600'>{getTranslation("account.activateLicenses.licensesTable.columns.info")}</th>
                <th className='border border-slate-400 p-2 bg-gray-background font-normal text-left pl-4 text-gray-600'>{getTranslation("account.activateLicenses.licensesTable.columns.status")}</th>
              </tr>
            </thead>
            <tbody>
              {trials?.map((obj, idx) => (
                <tr key={'row-' + idx}>
                  <td className='border border-slate-400 p-2 font-normal text-left pl-4 text-gray-600'>{obj.name}</td>
                  <td className='border border-slate-400 p-2 font-normal text-left pl-4 text-gray-600'>{obj.daysLeft}</td>
                  <td className='border border-slate-400 p-2 font-normal text-left pl-4 text-gray-600'>
                    <div dangerouslySetInnerHTML={{__html: obj.info}} />
                  </td>
                  <td className='border border-slate-400 p-2 font-normal text-left pl-4 text-gray-600'>
                    {
                      obj.status === "CanEvaluate" ?
                        <CustomButton
                          testdataId="activate-licenses-table-start-button"
                          label={getTranslation("account.activateLicenses.licensesTable.status.start")}
                          type='secondary'
                          onClick={() => selectTrial(obj)}
                        />
                      : obj.status === "AlreadyEvaluating" ?
                        getTranslation("account.activateLicenses.licensesTable.status.inUse")
                      : obj.status
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    )
}

export default ActivateLicenses;