import { useEffect, useRef, useState } from 'react';
import atc_logo from "../../../assets/images/logo.png";
import trimble_logo from "../../../assets/images/trimbleLogoBlue.svg";
import { languages } from '../../../assets/properties/languages';
import { useLocalization } from '../../providers/LocalizationProvider';
import { User } from '../../../model/User';
import { useNavigate, useLocation, useRouteLoaderData, useSearchParams } from 'react-router-dom';
import { PARAMETER_NAME_LOGIN_PATH, PARAMETER_NAME_LOGOUT_PATH } from '../../../assets/constants';

export function Header({user} : {user?: User}) {

    const [ URLSearchParams ] = useSearchParams();

    /**
     * User data from root loader.
     */
    const data = useRouteLoaderData('root') as any;
    if (!user) user = data?.user as User;

    /**
     * Localization.
     */
    const { language, setLocale, getTranslation } = useLocalization();

    /**
     * Routing items.
     */
    const location = useLocation();
    const navigate = useNavigate();

    /**
     * Dropdowns.
     */
    const [showLanguages, setShowLanguages] = useState(false);
    const [showProfileDropdown, setShowProfileDropdown] = useState(false);

    const languagesRef = useRef<HTMLDivElement>(null);
    const profileRef = useRef<HTMLDivElement>(null);

    /**
     * Closes dropdown on mouse click outside.
     */
    useEffect(() => {
        function handleClickOutside(event: any) {
            if (languagesRef?.current && !languagesRef.current.contains(event.target)) {
                setShowLanguages(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [languagesRef])

    /**
     * Closes dropdown on mouse click outside.
     */
    useEffect(() => {
        function handleClickOutside(event: any) {
            if (profileRef?.current && !profileRef.current.contains(event.target)) {
                setShowProfileDropdown(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [profileRef])

    const selectLanguage = (obj: string) => {
        setLocale(obj);
        setShowLanguages(false);
    }

    const navigateFromLogo = () => {
        //
        navigate({
            pathname: user ? "/account/" : "/",
            search: URLSearchParams.toString()
        });
    }

    const navigateToMyProfile = () => {
        navigate({pathname: "/account/", search: URLSearchParams.toString()})
        setShowProfileDropdown(false);
    }


    const signIn = async () => {
        //
        let loginPath = `${PARAMETER_NAME_LOGIN_PATH}&${URLSearchParams.toString()}`;
        window.location.href = loginPath;
    }

    const signOut = async () => {
        //
        let logoutPath = `${PARAMETER_NAME_LOGOUT_PATH}&${URLSearchParams.toString()}`;
        window.location.href = logoutPath;
    }

    const formatLanguageTagForUniversalNavigation = (language?: string) => {
        if (language !== "ja_JP") {
            return language;
        } else {
            return "jp_JP";
        }
    }

    return (
        <div id='top-nav' className='flex flex-row h-[56px] border-b-2 justify-between shrink-0'>
            <div id='top-nav-left' className='flex flex-row ml-16 space-x-4 items-center'>
                
                <div className='z-30 flex flex-row space-x-4 cursor-pointer' onClick={() => navigateFromLogo()}>
                    <img src={trimble_logo} alt='trimble-logo' className='h-3 sm:h-4 md:h-6 br-2' />
                    <div className='border-r-2' />
                    <img src={atc_logo} alt='tekla-logo' className='h-3 sm:h-4 md:h-6' />
                </div>
            </div>
            <div id='top-nav-right' className='flex flex-row items-center'>
                <div id='top-nav-languages' ref={languagesRef} className='flex flex-col relative z-30 text-xs sm:text-base'>
                    <div
                        testdata-id="header-select-language-button"
                        className={`flex flex-row text-trimble-blue hover:text-trimble-blue-hover items-center space-x-2 font-semibold cursor-pointer p-4 ${ showLanguages ? 'border-x-2' : ''}`}
                        onClick={() => setShowLanguages(!showLanguages)}
                    >
                        <div>{getTranslation("language." + language)}</div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" fill="currentColor" className="mi-solid mi-triangle-down" viewBox="0 0 24 24">
                                    <path d="M20 3H4C2.47 3 1.5 4.64 2.26 5.97l8 14.02c.77 1.34 2.72 1.34 3.48 0l8-14.02C22.5 4.64 21.53 3 20 3"/>
                        </svg>
                    </div>
                    <div className={`absolute z-30 border-2 block mt-[56px] w-[250px] text-left bg-white right-0 text-trimble-blue font-medium cursor-pointer ${ showLanguages ? '' : 'hidden'}`}>
                        {languages?.map((obj, i) => (
                            <div
                                testdata-id={`header-select-language-${obj}`}
                                key={'language-' + i}
                                className={` w-full py-2 px-2 hover:bg-gray-200 ${ (obj === language) ? 'border-l-4 border-trimble-blue bg-gray-200' : ''}`}
                                onClick={() => selectLanguage(obj)}
                            >
                                {getTranslation("language." + obj)}
                            </div>
                        ))}
                    </div>
                </div>
                <div id='top-nav-user' className='flex text-trimble-blue items-center font-semibold cursor-pointer z-30 text-xs sm:text-base'>
                    {user && user !== null ?
                        <div ref={profileRef} className='flex flex-col relative'>
                            <div className={`flex flex-row text-trimble-blue hover:text-trimble-blue-hover items-center space-x-2 font-semibold cursor-pointer p-4 ${ showProfileDropdown ? 'border-x-2' : ''}`} onClick={() => setShowProfileDropdown(!showProfileDropdown)}>
                                <div>{user.firstName + ' ' + user.lastName}</div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" fill="currentColor" className="mi-solid mi-triangle-down" viewBox="0 0 24 24">
                                    <path d="M20 3H4C2.47 3 1.5 4.64 2.26 5.97l8 14.02c.77 1.34 2.72 1.34 3.48 0l8-14.02C22.5 4.64 21.53 3 20 3"/>
                                </svg>
                            </div>
                            <div className={`absolute border-2 block mt-[56px] w-[250px] text-left bg-white right-0 text-trimble-blue font-medium cursor-pointer ${ showProfileDropdown ? '' : 'hidden'}`}>
                                <div
                                    testdata-id="header-profile-button"
                                    className={`w-full py-2 px-2 hover:bg-gray-200 border-l-4 border-trimble-blue bg-gray-background`}
                                    onClick={() => navigateToMyProfile()}
                                >
                                    {getTranslation("navigation.top.myProfile")}
                                </div>
                                <div
                                    testdata-id="header-signout-button"
                                    className={`w-full py-2 px-2 hover:bg-gray-200 }`}
                                    onClick={() => signOut()}
                                >
                                    {getTranslation("navigation.top.signOut")}
                                </div>
                            </div>
                        </div>
                        :
                        <div
                            testdata-id="header-signin-button"
                            className='flex flex-row text-trimble-blue items-center space-x-2 font-semibold cursor-pointer p-4'
                            onClick={() => signIn()}
                        >
                            {getTranslation("navigation.top.signIn")}
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default Header;