import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { routes } from './router';

function App() {

  return (
    <div id='main-container'>
        <RouterProvider router={createBrowserRouter(routes)} />
    </div>
  );
}

export default App;
