import OrganizationColumnOrganization from './organization/member';
import OrganizationColumnRoles from './roles';
import OrganizationColumnInvitations from './invitations';
import OrganizationColumnNoOrganization from './organization/non-member';
import { useLoaderData, useNavigate, useRouteLoaderData } from 'react-router-dom';
import { useEffect, useState } from 'react';
import CustomModal from '../../custom/CustomModal';
import CustomButton from '../../custom/CustomButton';
import { sendMembershipRequest } from '../../../assets/api';
import { useNotification } from '../../providers/NotificationProvider';
import { useLocalization } from '../../providers/LocalizationProvider';

export function MyOrganization() {

  const navigate = useNavigate();
  const { sendNotification } = useNotification();
  const { getTranslation, getTranslationWithParameters } = useLocalization();
  let { requests, suggestedOrganizations, pendingInvitations, organizationToRequest } = useLoaderData() as any;
  let { employeesGroup, groups, roles } = useRouteLoaderData("root") as any;
  const [isRequestModalVisible, setIsRequestModalVisible] = useState(false);
  const [modalTab, setModalTab] = useState<string>("employees");

  useEffect(() => {
    //
    if (organizationToRequest !== null) {
      setIsRequestModalVisible(true)
    }
  }, [organizationToRequest])

  const sendRequest = async (e: React.FormEvent<HTMLButtonElement>) => {
    //
    setIsRequestModalVisible(false);
    e.preventDefault();
    try {
      if (organizationToRequest != null) {
        //
        let response = await sendMembershipRequest(organizationToRequest.id, modalTab);
        if (response.status === 200) {
          sendNotification(getTranslation("account.organization.requestMembershipModal.notifications.sendSuccess"), "success");
          navigate('.', { replace: true })
        } else {
          let errorDescription = (await response.json() as any)?.error_description;
          sendNotification(errorDescription ? errorDescription : getTranslation("account.organization.requestMembershipModal.notifications.sendFailed"), "error");
        }
      }
    } catch (err) {
      sendNotification(getTranslation("account.organization.requestMembershipModal.notifications.sendFailed"), "error");
    }
    setModalTab("employees");
  }

  useEffect(() => {
    //
    if (isRequestModalVisible && organizationToRequest) {
      var el = document.getElementById("membership-request-tab");
      if (el) {
        el.onclick = function () {
          setModalTab("licenseConsumers");
        }
      }
    }
  }, [isRequestModalVisible])

  return (
    <div
      id='organization-page'
      className='flex flex-col gap-8'
    >
      {
        organizationToRequest !== null ?
          <CustomModal
            label="Send request"
            description={
              <div>
                {
                  modalTab === "employees" ?
                    <div className="space-y-6">
                      <p>
                        {getTranslationWithParameters({
                          key: "account.organization.requestMembershipModal.description_1",
                          parameters: {
                            organizationName: organizationToRequest.name
                          }
                        })}
                      </p>
                      {
                        <p dangerouslySetInnerHTML={{
                          __html: getTranslationWithParameters({
                            key: "account.organization.requestMembershipModal.description_2",
                            parameters: {
                              organizationName: organizationToRequest.name,
                              requestLinkStart: '<a id="membership-request-tab" style="cursor: pointer;">',
                              linkEnd: '</a>'
                            }
                          })
                        }} />
                      }
                    </div>
                    : modalTab === "licenseConsumers" ?
                      <p>
                        {getTranslationWithParameters({
                          key: "account.organization.requestMembershipModal.joinAsExternalDescription",
                          parameters: {
                            organizationName: organizationToRequest.name
                          }
                        })}
                      </p>
                      : null
                }
              </div>
            }
            isVisible={isRequestModalVisible}
            closeModal={() => setIsRequestModalVisible(false)}
            buttons={[
              <CustomButton
                testdataId='my-organization-request-membership-modal-cancel-button'
                label={getTranslation("account.organization.requestMembershipModal.buttons.cancel")}
                type="secondary"
                onClick={() => setIsRequestModalVisible(false)}
              />,
              <CustomButton
                testdataId='my-organization-request-membership-modal-send-button'
                label={getTranslation("account.organization.requestMembershipModal.buttons.sendRequest")}
                type="primary"
                onClick={sendRequest}
              />
            ]}
          />
          : null
      }
      <h1
        className='text-2xl text-gray-500'
      >
        {getTranslation("account.organization.myOrganizationTitle")}
      </h1>
      <div
        id='profile-page'
        className='flex flex-col space-y-8'
      >
        {
          employeesGroup ?
            <OrganizationColumnOrganization
              employeesGroup={employeesGroup}
              roles={roles}
              groups={groups}
              suggestedOrganizations={suggestedOrganizations}
              membershipRequests={requests}
            />
            :
            <OrganizationColumnNoOrganization
              membershipRequests={requests}
              suggestedOrganizations={suggestedOrganizations}
            />
        }
        <OrganizationColumnRoles roles={roles} groups={groups} employeesGroup={employeesGroup} />
        <OrganizationColumnInvitations employeesGroup={employeesGroup} invitations={pendingInvitations} />
      </div>
    </div>
  )
}

export default MyOrganization;