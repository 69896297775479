import { ReactElement } from "react";

export function CustomModal(
    {
        id,
        testdataId,
        label, 
        description, 
        buttons, 
        isVisible, 
        closeModal
    } : {
        id?: string,
        testdataId?: string,
        label?: string | ReactElement | undefined, 
        description?: string | ReactElement | undefined, 
        buttons: Array<ReactElement> | undefined, 
        isVisible: boolean | undefined, 
        closeModal: Function 
    }) {

    return (
        <>
            {
            isVisible ? 
            <div id={id} testdata-id={testdataId} className="fixed top-0 left-0 right-0 z-30 w-full h-full bg-gray-500/50">
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white shadow max-w-[720px]">
                <div className="flex items-start justify-between p-4 rounded-t">
                    <h3 className="text-xl font-semibold text-black mt-2">
                        {label}
                    </h3>
                    <button onClick={() => closeModal()} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center">
                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                        </svg>
                    </button>
                </div>
                <div className="p-6">
                    {description}
                </div>
                <div className="p-6 space-x-2 flex justify-end">
                    {buttons?.map((item, index) => (
                        item
                    ))}
                </div>
                </div>
            </div>
            : null
            }
        </>
    )
}

export default CustomModal;