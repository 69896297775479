import { useEffect, useState } from 'react';
import { User } from '../../../model/User';
import SideNavigation from '../../navigation/side';
import { Outlet, useRouteLoaderData, useSearchParams } from "react-router-dom";
import { useBanner } from '../../providers/BannerProvider';
import { PARAMETER_NAME_CONTINUE_TO, PARAMETER_NAME_RETURN_TO, PARAMETER_NAME_SKIP_OPTIONAL_REDIRECTS } from '../../../assets/constants';
import { OrganizationRole } from '../../../model/OrganizationRole';
import { OrganizationGroup } from '../../../model/OrganizationGroup';
import { useLocalization } from '../../providers/LocalizationProvider';

export function AccountLayout() {

  let data = useRouteLoaderData('root') as any;
  let user = data?.user as User;
  let roles = data?.roles as OrganizationRole[];
  let employeesGroup = data?.employeesGroup as OrganizationGroup;

  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const { addBanner } = useBanner();
  const { getTranslation } = useLocalization();

  useEffect(() => {
    //
    let returnToParameter = searchParams.get(PARAMETER_NAME_RETURN_TO);
    if (returnToParameter) {
      //
      addBanner(getTranslation("banner.backTo"), !user?.isProfileCompleted, returnToParameter);
    } else {
      //
      let continueToParameters = searchParams.getAll(PARAMETER_NAME_CONTINUE_TO);
      if (continueToParameters && continueToParameters.length > 0) {
        // check if any continueTo is internal or external, banner should only be visible for external calls
        for (const continueToParameter of continueToParameters) {
          //
          if (continueToParameter) {
            //
            const paramsWithinContinueTo = new URLSearchParams(continueToParameter);
            if (paramsWithinContinueTo.has(PARAMETER_NAME_SKIP_OPTIONAL_REDIRECTS)) {
              //
              break;
            }
          }
          const isExternalCall = continueToParameter?.match(/^http|^https|^www/);
          if (isExternalCall) {
            //
            addBanner(getTranslation("banner.continueTo"), !user?.isProfileCompleted, continueToParameter);
            break;
          } else {
            //
            if (continueToParameter.startsWith("/login/index.html")) {
              //
              const paramToContinueTo = decodeURIComponent(searchParams.toString().replace("continueTo=", ""));
              addBanner(getTranslation("banner.continueTo"), !user?.isProfileCompleted, paramToContinueTo);
              break;
            }
            if (continueToParameter.startsWith("/oauth2/")) {
              //
              const [redirectPath, redirectParameter] = continueToParameter.split(/\?(.*)/s);
              const [redirectParamKey, redirectParamValue] = redirectParameter.split(/\=(.*)/s);
              const copiedParamsToModify = new URLSearchParams(searchParams);
              copiedParamsToModify.delete(PARAMETER_NAME_CONTINUE_TO);
              const paramToContinueTo = `${redirectPath}?${redirectParamKey}=${redirectParamValue}&${copiedParamsToModify.toString()}`
              addBanner(getTranslation("banner.continueTo"), !user?.isProfileCompleted, paramToContinueTo);
              break;
            }
          }
        }
      }
    }
    setLoading(false);
  }, [data])

  return (
    <div
      id="account-layout"
      className='flex flex-row w-full'
    >
      <SideNavigation user={user} roles={roles} employeesGroup={employeesGroup} />
      <div
        id='account-outlet'
        className='ml-8 mb-12'
      >
        {
          loading ?
            <></> :
            <Outlet />
        }
      </div>
    </div>
  )
}

export default AccountLayout;