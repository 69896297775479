import Header from '../../navigation/header';
import { Outlet } from "react-router-dom";
import { BannerProvider } from '../../providers/BannerProvider';
import { NotificationProvider } from '../../providers/NotificationProvider';
import { User } from '../../../model/User';

export function MainLayout({ children, user } : { children?: any, user?: User }) {

  return (
    <div
      id="main-layout"
      className='flex flex-col'
    >
      <BannerProvider>
        <NotificationProvider>
          <Header user={user}/>
          <div className='flex h-full grow'>
            {
              children ?? <Outlet />
            }
          </div>
        </NotificationProvider>
      </BannerProvider>
    </div>
    )
}

export default MainLayout;