import { createContext, useContext, useState } from "react";

interface ContextProps {
    readonly addBanner: (
        title: string,
        disabled?: boolean,
        link?: string,
    ) => void;
    readonly removeBanner: () => void;
}

const BannerContext = createContext<ContextProps>({
    addBanner: () => {},
    removeBanner: () => {}
});

export const useBanner = () => {
	return useContext(BannerContext)
}

export const BannerProvider = ({ children }: { children: React.ReactNode }) => {

    const [ title, setTitle ] = useState<string | undefined>();
    const [ disabled, setDisabled ] = useState<boolean | undefined>(true);
    const [ link, setLink ] = useState<string | undefined>();
    const [ visible, setVisible ] = useState<boolean>(false);

    const addBanner = async (
        title: string,
        disabled?: boolean,
        link?: string
    ) => {
        setTitle(title);
        setDisabled(disabled);
        setLink(link);
        setVisible(true);
    }

    const removeBanner = async (
    ) => {
        setTitle(undefined);
        setDisabled(undefined);
        setLink(undefined);
        setVisible(false);
    }

    const value = {
        addBanner: addBanner,
        removeBanner: removeBanner
    }

    return (
        <BannerContext.Provider value={value}>
            {visible ?
                <a
                    id="banner"
                    className={`${disabled ? "cursor-not-allowed" : "cursor-pointer"} bg-trimble-blue/80 hover:bg-trimble-blue fixed bottom-0 w-full h-24 z-20 flex`}
                    onClick={
                        () => !disabled ? window.location.href = link ? link : "" : null
                    }
                    rel="noopener"
                >
                    <h1 className="m-auto text-white text-4xl">{title}</h1>
                </a>
            : null
            }
            {children}
        </BannerContext.Provider>
    );
};