export let languages =  [
    "cs_CZ",
    "de_DE",
    "en_US",
    "es_ES",
    "fr_FR",
    "hu_HU",
    "it_IT",
    "ja_JP",
    "ko_KR",
    "nl_NL",
    "pl_PL",
    "pt_PT",
    "pt_BR",
    "ru_RU",
    "zh_CN",
    "zh_TW",
]