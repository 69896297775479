import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { synchronizeUserDataWithExternalProvider } from '../../../../assets/api';
import { User } from '../../../../model/User';
import { useLocalization } from '../../../providers/LocalizationProvider';
import { useNotification } from '../../../providers/NotificationProvider';

export function ProfileColumnTid({ user }: { user: User }) {

  const navigate = useNavigate();
  const { sendNotification } = useNotification();
  const { getTranslation } = useLocalization();
  const [syncLoader, setSyncLoader] = useState(false);

  const synchronizeProfile = async () => {
    //
    setSyncLoader(true);
    await synchronizeUserDataWithExternalProvider()
      .then(res => {
        //
        if (res.status !== 200) throw Error("Not successful status code")
        sendNotification(getTranslation("account.profile.tid.sync.notifications.success"), "success");
        navigate({ pathname: '.', search: window.location.search }, { replace: true });
      })
      .catch(err => {
        //
        sendNotification(getTranslation("account.profile.tid.sync.notifications.failed"), "error");
      })
    setSyncLoader(false);
  }

  return (
    <div
      id='profile-column-tid'
      className='md:w-[356px] my-2 mx-4'
    >
      <div
        id='profile-tid'
        className='flex flex-col gap-6 h-full bg-gray-100 p-4 md:mr-16 w-full'
      >
        <div className='flex flex-row justify-between'>
          <h2 className='text-lg font-semibold text-gray-500'>
            {getTranslation("account.profile.tid.title")}
          </h2>
          <div className='flex flex-row space-x-2'>
            <a
              testdata-id="profile-tid-button"
              className='flex flex-row font-semibold text-trimble-blue hover:text-trimble-orange text-sm cursor-pointer space-x-1'
              href={window.ENV.PROFILES_URL} target='_'
            >
              <div>{getTranslation("account.profile.tid.edit")}</div>
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className={`mi-solid mi-launch text-black m-auto h-3 w-3 mt-0`} viewBox="0 0 24 24">
                <path d="M19.78 19.78H4.22V4.22h6.67c.61 0 1.11-.5 1.11-1.11C12 2.5 11.5 2 10.89 2H3c-.55 0-1 .45-1 1v18c0 .55.45 1 1 1h18c.55 0 1-.45 1-1v-7.89c0-.61-.5-1.11-1.11-1.11-.61 0-1.11.5-1.11 1.11v6.67ZM14.22 3.11c0 .61.5 1.11 1.11 1.11h2.88L8.07 14.36c-.43.43-.43 1.13 0 1.57.43.43 1.13.43 1.57 0L19.78 5.79v2.88c0 .61.5 1.11 1.11 1.11.61 0 1.11-.5 1.11-1.11V3c0-.55-.45-1-1-1h-5.67c-.61 0-1.11.5-1.11 1.11Z" />
              </svg>
            </a>
            <button
              disabled={syncLoader}
              testdata-id="profile-tid-button"
              className={`flex flex-row font-semibold text-sm text-trimble-blue ${syncLoader ? "cursor-wait" : "hover:text-trimble-orange cursor-pointer"} space-x-1`}
              onClick={() => synchronizeProfile()}
            >
              <div>{getTranslation("account.profile.tid.sync")}</div>
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className={`mi-solid mi-launch text-black m-auto ${syncLoader ? "animate-spin" : ""} h-3 w-3 mt-0`} viewBox="0 0 24 24">
                <path d="M13.19 15.5a.965.965 0 0 0-1.08-.21 1 1 0 0 0-.62.93v1.27a5.503 5.503 0 0 1-4.6-7.54c.22-.54.09-1.16-.34-1.59-.36-.36-.87-.51-1.36-.41-.5.1-.91.44-1.1.91-.4.99-.61 2.05-.61 3.14 0 4.52 3.54 8.23 8 8.49v1.3a1 1 0 0 0 .62.93c.12.05.25.07.37.07.26 0 .52-.1.71-.3l2.79-2.79a1 1 0 0 0 .3-.71 1 1 0 0 0-.3-.71l-2.79-2.79Zm-.7-11.98v-1.3a1 1 0 0 0-.62-.93 1 1 0 0 0-1.08.22L7.99 4.3a.99.99 0 0 0 0 1.42l2.8 2.8c.29.28.72.36 1.1.2s.61-.51.61-.92V6.53a5.503 5.503 0 0 1 4.6 7.54c-.22.54-.09 1.16.34 1.59.29.29.67.44 1.06.44.1 0 .2 0 .3-.03.5-.1.91-.44 1.1-.91.4-.99.61-2.05.61-3.14 0-4.52-3.54-8.23-8-8.49Z" />
              </svg>
            </button>
          </div>
        </div>
        <div className='text-xs space-y-2 text-gray-500'>
          <div>{getTranslation("account.profile.tid.description_1")}</div>
          <div>{getTranslation("account.profile.tid.description_2")}</div>
        </div>
        <div className='flex flex-col space-y-2 text-sm'>
          <div>
            <div className='font-bold text-gray-600'>
              {getTranslation("account.profile.tid.firstName")} *
            </div>
            <div
              className='text-gray-500'
            >
              {user?.firstName}
            </div>
          </div>
          <div>
            <div className='font-bold text-gray-600'>
              {getTranslation("account.profile.tid.lastName")} *
            </div>
            <div
              className='text-gray-500'
            >
              {user?.lastName}
            </div>
          </div>
          <div>
            <div className='font-bold text-gray-600'>
              {getTranslation("account.profile.tid.email")} *
            </div>
            <div
              className='text-gray-500'
            >
              {user?.email}
            </div>
          </div>
          <div>
            <div className='font-bold text-gray-600'>
              {getTranslation("account.profile.tid.country.title")} *
            </div>
            <div
              className='text-gray-500'
            >
              {
                user?.address?.country
                  ? getTranslation("account.profile.tid.country." + user?.address?.country)
                  : <>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="mi-solid mi-alert fill-trimble-orange -mb-[2px]" viewBox="0 0 24 24">
                      <path d="M22.87 19.04 13.72 3c-.76-1.33-2.68-1.33-3.44 0L1.13 19.04C.38 20.36 1.33 22 2.85 22h18.3c1.52 0 2.47-1.64 1.72-2.96m-11.92-9.9c0-.63.45-1.15 1.06-1.15s1.06.51 1.06 1.15c0 .04-.01.12-.01.14l-.39 5.04c-.04.44-.3.7-.66.7s-.62-.26-.66-.7l-.4-5.04zm1.06 9.89c-.67 0-1.21-.54-1.21-1.21s.54-1.19 1.21-1.19 1.21.54 1.21 1.19-.54 1.21-1.21 1.21" />
                    </svg>
                    <span>
                      {getTranslation("account.profile.tid.country.countryMissing")}
                    </span>
                  </>
              }
            </div>
          </div>
          <div>
            <div className='font-bold text-gray-600'>
              {getTranslation("account.profile.tid.language.title")}
            </div>
            <div
              className='text-gray-500'
            >
              {user?.language ? getTranslation("account.profile.tid.language." + user?.language) : ""}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfileColumnTid;