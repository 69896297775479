import { OrganizationRole } from '../../../../model/OrganizationRole';
import { OrganizationGroup } from '../../../../model/OrganizationGroup';
import { useLocalization } from '../../../providers/LocalizationProvider';
import { useEffect, useState } from 'react';

export function OrganizationColumnRoles({roles, groups, employeesGroup} : {roles: Array<OrganizationRole>, groups: Array<OrganizationGroup>, employeesGroup: OrganizationGroup}) {
  
  const { getTranslation } = useLocalization();
  const [ collectedRoles, setCollectedRoles ] = useState<Array<any>>([]);

  useEffect(() => {
    //
    const mergeAndCollectRoles = () => {
      //
      let output = new Array<any>();
      roles?.forEach(function(item) {
        // filter out current organization's groups and roles
        if (item.organizationId === employeesGroup?.organizationId) {
          //
          return;
        }
        var existing = output.filter(function(v, i) {
          return v.organizationName === item.organizationName;
        });
        if (existing.length) {
          var existingIndex = output.indexOf(existing[0]);
          output[existingIndex]
            .roles
            .push(item.name
              .replace(/([A-Z])/g, ' $1')
              .trim()
            );
        } else {
          output.push({
            organizationName: item.organizationName,
            roles: [
              item.name
                .replace(/([A-Z])/g, ' $1')
                .trim(),
            ]
          });
        }
      });
      groups?.forEach(function(item) {
        // filter out current organization's groups and roles
        if (item.organizationId === employeesGroup?.organizationId) {
          //
          return;
        }
        var existing = output.filter(function(v, i) {
          return v.organizationName === item.organizationName;
        });
        if (existing.length) {
          var existingIndex = output.indexOf(existing[0]);
          output[existingIndex]
            .roles
            .push(item.type === "licenseConsumers" ? "External license user" : item.name);
        } else {
          output.push({
            organizationName: item.organizationName,
            roles: [
              item.type === "licenseConsumers" ? "External license user" : item.name
            ]
          });
        }
      });
      setCollectedRoles(output);
    };
    mergeAndCollectRoles();
  }, [roles, groups]);

  return (
    <>
    {
      collectedRoles?.length > 0 ?
      <div
        testdata-id="organization-roles"
        id='organization-column-roles'
        className='flex flex-col gap-4 justify-center h-full md:w-[720px] text-gray-600'
      >
        <div
          className='font-extrabold text-gray-600'
        >
          {getTranslation("account.organization.yourRolesInOtherOrganizations")}
        </div>
        <table className='table-auto border border-gray-600'>
          <thead className=''>
            <tr>
              <th className='border border-slate-400 p-2 bg-[#f1f1f6] font-normal text-left pl-4 text-gray-600'>{getTranslation("account.organization.otherRolesTable.organizationColumn")}</th>
              <th className='border border-slate-400 p-2 bg-[#f1f1f6] font-normal text-left pl-4 text-gray-600'>{getTranslation("account.organization.otherRolesTable.rolesColumn")}</th>
            </tr>
          </thead>
          <tbody>
            {collectedRoles.map((obj: any, idx: number) => (
              <tr key={'row-' + idx}>
                <td className='border border-slate-400 p-2 font-normal text-left pl-4 text-gray-600'>{obj.organizationName}</td>
                <td className='border border-slate-400 p-2 font-normal text-left pl-4 text-gray-600'>{obj?.roles?.join(', ')}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      : null
    }
    </>
    )
}

export default OrganizationColumnRoles;