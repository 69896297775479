import { ModusCheckbox } from '@trimble-oss/modus-react-components';
import { useEffect, useState } from 'react';
import { useLoaderData, useRouteLoaderData } from 'react-router-dom';
import { useLocalization } from '../../providers/LocalizationProvider';
import { AvailableLicense } from '../../../model/AvailableLicense';
import { OrganizationGroup } from '../../../model/OrganizationGroup';
import { OrganizationRole } from '../../../model/OrganizationRole';

export function Licenses() {

  const { getTranslation, getTranslationWithParameters } = useLocalization();
  let loaderData = useLoaderData() as any;
  let { employeesGroup, groups, roles } = useRouteLoaderData("root") as { employeesGroup: OrganizationGroup, groups: OrganizationGroup[], roles: OrganizationRole[] };
  const licenses = loaderData.licenses as Array<AvailableLicense>;
  let [showExpired, setShowExpired] = useState(true);
  let [sortField, setSortField] = useState('name');
  let [sortDesc, setSortDesc] = useState(true);

  useEffect(() => {
    setSortDesc(true);
  }, [sortField])

  const alpabeticalSort = (a: AvailableLicense, b: AvailableLicense) => {
    var nameA;
    var nameB;
    switch (sortField) {
      case 'name':
        nameA = a.licensedItem.toUpperCase();
        nameB = b.licensedItem.toUpperCase();
        break;
      case 'owner':
        nameA = a.owner.toUpperCase();
        nameB = b.owner.toUpperCase();
        break;
      case 'status':
        nameA = a.status.toUpperCase();
        nameB = b.status.toUpperCase();
        break;
      default:
        nameA = a.licensedItem.toUpperCase();
        nameB = b.licensedItem.toUpperCase();
    }
    return sortDesc ? ((nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0) : ((nameA < nameB) ? 1 : (nameA > nameB) ? -1 : 0);
  }

  return (
    <div
      id='my-licenses-page'
      className='w-full flex flex-col space-y-8'
    >
      <div
        id='landing-col-left'
        className='flex flex-col gap-6 justify-center h-full w-full'
      >
        <h1
          className='text-2xl text-gray-500'
        >
          {getTranslation("account.licenses.myLicensesTitle")}
        </h1>
        <div className='flex flex-col space-y-8'>
          <ModusCheckbox
            label={getTranslation("account.licenses.showExpiredLicenses")}
            onCheckboxClick={() => setShowExpired(!showExpired)}
            checked={showExpired}
          />
          <table className='table-auto border border-gray-600 md:w-[720px]'>
            <thead className=''>
              <tr>
                <th
                  className='border border-slate-400 p-2 bg-[#f1f1f6] font-normal text-left pl-4 text-gray-600 cursor-pointer'
                  onClick={() => {
                    setSortField('name')
                    setSortDesc(!sortDesc);
                  }}
                >
                  <div className='flex flex-row space-x-2'>
                    <h4 className='border-none'>{getTranslation("account.licenses.licensesTable.columns.name")}</h4>
                    {
                      sortField === "name" && sortDesc ?
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          className={`mi-solid mi-sort-arrow-down ${sortField === "name" ? "fill-gray-600" : "fill-gray-300"}`}
                          viewBox="0 0 24 24"
                        >
                          <path d="M16.3 14.82a1 1 0 0 0-1.41-.11l-1.88 1.88H13V5c0-.55-.45-1-1-1s-1 .45-1 1v11.59l-1.88-1.88a1 1 0 0 0-1.41 1.41l3.59 3.59c.39.39 1.02.39 1.41 0l3.59-3.59a1 1 0 0 0 0-1.3" />
                        </svg>
                        :
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          className={`mi-solid mi-sort-arrow-up ${sortField === "name" ? "fill-gray-600" : "fill-gray-300"}`}
                          viewBox="0 0 24 24"
                        >
                          <path d="M16.29 7.88 12.7 4.29a.996.996 0 0 0-1.41 0L7.7 7.88a1 1 0 0 0 1.41 1.41l1.88-1.88H11V19c0 .55.45 1 1 1s1-.45 1-1V7.41l1.88 1.88a1 1 0 0 0 1.41-1.41" />
                        </svg>
                    }
                  </div>
                </th>
                <th
                  className='border border-slate-400 p-2 bg-[#f1f1f6] font-normal text-left pl-4 text-gray-600 cursor-pointer'
                  onClick={() => {
                    setSortField('owner')
                    setSortDesc(!sortDesc);
                  }}
                >
                  <div className='flex flex-row space-x-2'>
                    <h4 className='border-none'>{getTranslation("account.licenses.licensesTable.columns.owner")}</h4>
                    {
                      sortField === "owner" && sortDesc ?
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          className={`mi-solid mi-sort-arrow-down ${sortField === "owner" ? "fill-gray-600" : "fill-gray-300"}`}
                          viewBox="0 0 24 24"
                        >
                          <path d="M16.3 14.82a1 1 0 0 0-1.41-.11l-1.88 1.88H13V5c0-.55-.45-1-1-1s-1 .45-1 1v11.59l-1.88-1.88a1 1 0 0 0-1.41 1.41l3.59 3.59c.39.39 1.02.39 1.41 0l3.59-3.59a1 1 0 0 0 0-1.3" />
                        </svg>
                        :
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          className={`mi-solid mi-sort-arrow-up ${sortField === "owner" ? "fill-gray-600" : "fill-gray-300"}`}
                          viewBox="0 0 24 24"
                        >
                          <path d="M16.29 7.88 12.7 4.29a.996.996 0 0 0-1.41 0L7.7 7.88a1 1 0 0 0 1.41 1.41l1.88-1.88H11V19c0 .55.45 1 1 1s1-.45 1-1V7.41l1.88 1.88a1 1 0 0 0 1.41-1.41" />
                        </svg>
                    }
                  </div>
                </th>
                <th
                  className='border border-slate-400 p-2 bg-[#f1f1f6] font-normal text-left pl-4 text-gray-600 cursor-pointer'
                  onClick={() => {
                    setSortField('status')
                    setSortDesc(!sortDesc);
                  }}
                >
                  <div className='flex flex-row space-x-2'>
                    <h4 className='border-none'>{getTranslation("account.licenses.licensesTable.columns.status")}</h4>
                    {
                      sortField === "status" && sortDesc ?
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          className={`mi-solid mi-sort-arrow-down ${sortField === "status" ? "fill-gray-600" : "fill-gray-300"}`}
                          viewBox="0 0 24 24"
                        >
                          <path d="M16.3 14.82a1 1 0 0 0-1.41-.11l-1.88 1.88H13V5c0-.55-.45-1-1-1s-1 .45-1 1v11.59l-1.88-1.88a1 1 0 0 0-1.41 1.41l3.59 3.59c.39.39 1.02.39 1.41 0l3.59-3.59a1 1 0 0 0 0-1.3" />
                        </svg>
                        :
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          className={`mi-solid mi-sort-arrow-up ${sortField === "status" ? "fill-gray-600" : "fill-gray-300"}`}
                          viewBox="0 0 24 24"
                        >
                          <path d="M16.29 7.88 12.7 4.29a.996.996 0 0 0-1.41 0L7.7 7.88a1 1 0 0 0 1.41 1.41l1.88-1.88H11V19c0 .55.45 1 1 1s1-.45 1-1V7.41l1.88 1.88a1 1 0 0 0 1.41-1.41" />
                        </svg>
                    }
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {licenses?.length > 0 ?
                licenses
                  ?.filter((obj) => !(obj.owner !== employeesGroup?.organizationName && obj.status === "licenseAssignmentMissing" || obj.status === "licenseAssignmentDenied"))
                  .filter((obj) => showExpired ? true : obj.status !== "licenseExpired")
                  .sort((a, b) => alpabeticalSort(a, b))
                  .map((obj, idx) => (
                    <tr key={'row-' + idx} className={`${obj.status === "licenseExpired" ? "text-gray-300" : "text-gray-600"}`}>
                      <td className='border border-slate-400 p-2 font-normal text-left pl-4'>
                        {obj.licensedItem}
                      </td>
                      <td className='border border-slate-400 p-2 font-normal text-left pl-4'>
                        {obj.owner
                        }
                      </td>
                      <td className='border border-slate-400 p-2 font-normal text-left pl-4'>
                        {
                          obj.status === "scopeRequired" &&
                            obj.allowedHardwareName !== "any" &&
                            obj.allowedHardwareName !== "none" ?
                            obj.allowedHardwareName === "single" ?
                              <>
                                <strong>{getTranslationWithParameters({ key: "account.licenses.licensesTable.status.floatingHardwareScope.title" })}</strong>
                                &nbsp;{getTranslationWithParameters({ key: "account.licenses.licensesTable.status.floatingHardwareScope.info", parameters: {} })}
                              </>
                              :
                              <>
                                <strong>{getTranslationWithParameters({ key: "account.licenses.licensesTable.status.boundHardwareScope.title" })}</strong>
                                &nbsp;{getTranslationWithParameters({ key: "account.licenses.licensesTable.status.boundHardwareScope.info", parameters: { hardwareName: obj.allowedHardwareName } })}
                              </>
                            :
                            obj.status === "licenseAlreadyactivatedOnThisDevice" ?
                              <>
                                <strong>{getTranslationWithParameters({ key: "account.licenses.licensesTable.status.licenseAlreadyActivatedOnThisDevice.title" })}</strong>
                                &nbsp;{getTranslationWithParameters({ key: "account.licenses.licensesTable.status.licenseAlreadyActivatedOnThisDevice.info", parameters: { hardwareName: obj.allowedHardwareName } })}
                              </>
                              :
                              <>
                                <strong>{getTranslationWithParameters({ key: `account.licenses.licensesTable.status.${obj.status}.title` })}</strong>
                                &nbsp;{getTranslationWithParameters({ key: `account.licenses.licensesTable.status.${obj.status}.info`, parameters: { allowedVersions: obj.allowedVersions } })}
                              </>
                        }
                      </td>
                    </tr>
                  ))
                :
                <tr className="text-gray-600 border-opacity-0">
                  <td className='border border-slate-400 p-2 font-normal text-left pl-4 border-r-0'>
                    {getTranslation("account.licenses.licensesTable.noLicensesFound")}
                  </td>
                  <td className='border border-slate-400 p-2 font-normal text-left pl-4 border-x-0'>
                  </td>
                  <td className='border border-slate-400 p-2 font-normal text-left pl-4 border-l-0'>
                  </td>
                </tr>}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Licenses;