import { useEffect, useState } from 'react';
import { OrganizationGroup } from '../../../../model/OrganizationGroup';
import CustomModal from '../../../custom/CustomModal';
import CustomButton from '../../../custom/CustomButton';
import { useNotification } from '../../../providers/NotificationProvider';
import {
  acceptInvitation as acceptInvitationOperation,
  rejectInvitation as rejectInvitationOperation,
  sendMembershipRequest
} from '../../../../assets/api';
import { Invitation } from '../../../../model/Invitation';
import { useNavigate } from 'react-router-dom';
import { useLocalization } from '../../../providers/LocalizationProvider';
import moment from 'moment';

export function OrganizationColumnInvitations({ employeesGroup, invitations }: { employeesGroup?: OrganizationGroup, invitations?: Array<Invitation> }) {

  const navigate = useNavigate();
  const { getTranslation, getTranslationWithParameters } = useLocalization();
  const { sendNotification } = useNotification();
  let [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  let [currentInvitation, setCurrentInvitation] = useState<Invitation | null>(null);
  let [currentRoles, setCurrentRoles] = useState<string | null>(null);
  let [visibleInvitations, setVisibleInvitations] = useState<Array<Invitation> | undefined>(invitations);
  const [modalTab, setModalTab] = useState<string>("invitation");

  const openInvitation = (invitation: any, rolesOfInvitation: string) => {
    //
    setCurrentInvitation(invitation);
    setCurrentRoles(rolesOfInvitation);
    setIsModalVisible(true);
  }

  const closeModal = () => {
    setModalTab("invitation");
    setCurrentInvitation(null);
    setIsModalVisible(false);
  }

  const rejectInvitation = async () => {
    //
    setIsModalVisible(false);
    try {
      if (currentInvitation !== null) {
        //
        let invitationCode = currentInvitation.invitationCode ? currentInvitation.invitationCode : currentInvitation.id;
        let response = await rejectInvitationOperation(currentInvitation.type, invitationCode);
        if (response.status === 200) {
          sendNotification(getTranslation("account.organization.invitationsModal.notifications.rejectSuccess"), 'info');
          removeInvitationFromTable(currentInvitation);
        } else {
          let errorDescription = (await response.json() as any)?.error_description;
          sendNotification(errorDescription ? errorDescription : getTranslation("account.organization.invitationsModal.notifications.rejectFailed"), "error");
        }
      }
    } catch (err) {
      sendNotification(getTranslation("account.organization.invitationsModal.notifications.rejectFailed"), "error");
    }
  }

  const acceptInvitation = async () => {
    //
    setIsModalVisible(false);
    try {
      if (currentInvitation !== null) {
        //
        let invitationCode = currentInvitation.invitationCode ? currentInvitation.invitationCode : currentInvitation.id;
        let response = await acceptInvitationOperation(currentInvitation.type, invitationCode);
        if (response.status === 200) {
          sendNotification(getTranslation("account.organization.invitationsModal.notifications.acceptSuccess"), 'success');
          navigate('.', { replace: true });
          removeInvitationFromTable(currentInvitation);
        } else {
          let errorDescription = (await response.json() as any)?.error_description;
          sendNotification(errorDescription ? errorDescription : getTranslation("account.organization.invitationsModal.notifications.acceptFailed"), "error");
        }
      }
    } catch (err) {
      sendNotification(getTranslation("account.organization.invitationsModal.notifications.acceptFailed"), "error");
    }
  }

  const requestToJoin = async () => {
    //
    setIsModalVisible(false);
    try {
      if (currentInvitation != null) {
        //
        let invitationCode = currentInvitation.invitationCode ? currentInvitation.invitationCode : currentInvitation.id;
        let rejectResponse = await rejectInvitationOperation(currentInvitation.type, invitationCode);
        if (rejectResponse.status !== 200) {
          //
          sendNotification(getTranslation("account.organization.invitationsModal.notifications.rejectFailed"), "error");
          setCurrentInvitation(null);
          setModalTab("invitation");
          return;
        }
        //
        let requestResponse = await sendMembershipRequest(currentInvitation.organization.id, "licenseConsumers");
        if (requestResponse.status === 200) {
          sendNotification(getTranslation("account.organization.invitationsModal.notifications.sendSuccess"), "success");
          navigate('.', { replace: true })
        } else {
          let errorDescription = (await requestResponse.json() as any)?.error_description;
          sendNotification(errorDescription ? errorDescription : getTranslation("account.organization.invitationsModal.notifications.sendFailed"), "error");
        }
        removeInvitationFromTable(currentInvitation);
      }
    } catch (err) {
      sendNotification(getTranslation("account.organization.invitationsModal.notifications.sendFailed"), "error");
    }
    setCurrentInvitation(null);
    setModalTab("invitation");
  }

  const removeInvitationFromTable = (invitation: Invitation) => {
    setVisibleInvitations(invitations?.filter((inv) => inv.id !== invitation.id));
  }

  const loadRolesColumn = (invitation: any) => {
    //
    let groups = invitation?.groups ? invitation.groups.map((group: any) => {
      if (group?.type === "employees") {
        return "Employee"
      }
      if (group?.type === "licenseConsumers") {
        return "External license user"
      }
      return group?.name
    }) : [];
    let roles = invitation?.roles ? invitation.roles.map((role: any) =>
      role?.name
        .replace(/([A-Z])/g, ' $1')
        .trim()
    ) : [];
    let combined = groups.concat(roles);
    return combined.join(', ');
  }

  useEffect(() => {
    //
    if (currentInvitation) {
      var el = document.getElementById("invitation-request-tab");
      if (el) {
        el.onclick = function () {
          setModalTab("request");
        }
      }
    }
  }, [currentInvitation])

  return (
    <div
      id='organization-column-invitations'
      className='flex flex-col justify-center h-full w-full text-gray-600 gap-4'
    >
      <CustomModal
        id="invitation-modal"
        testdataId="organization-invitations-modal"
        label={getTranslation("account.organization.invitationsModal.title")}
        description={
          <div className="p-6 space-y-2">
            {
              modalTab === "invitation" ?
                <>
                  <p className="text-base text-black">
                    {
                      currentInvitation?.groups?.find((group) => group?.type === "CollaborationGroup") ?
                        getTranslationWithParameters({
                          key: "account.organization.invitationsModal.collaborationGroupDescription",
                          parameters: {
                            groupName: currentInvitation?.groups?.find((group) => group?.type === "CollaborationGroup")?.name,
                          }
                        })
                        :
                        getTranslationWithParameters({
                          key: "account.organization.invitationsModal.description_1",
                          parameters: {
                            organizationName: currentInvitation?.organization?.name,
                            roles: currentRoles?.toLocaleLowerCase()
                          }
                        })
                    }
                  </p>
                  {
                    employeesGroup && currentInvitation?.groups?.find((group) => group?.type === "employees") ?
                      <>
                        <p
                          className="text-base text-black"
                          dangerouslySetInnerHTML={{
                            __html: getTranslationWithParameters({
                              key: "account.organization.invitationsModal.description_2",
                              parameters: {
                                boldStart: "<span class=\"font-bold\">",
                                organizationName: employeesGroup?.organizationName,
                                boldEnd: "</span>"
                              }
                            })
                          }}
                        />
                        {
                          <p
                            className="text-base text-black"
                            dangerouslySetInnerHTML={{
                              __html: getTranslationWithParameters({
                                key: "account.organization.invitationsModal.description_3",
                                parameters: {
                                  invitationOrganizationName: currentInvitation?.organization.name,
                                  currentOrganizationName: employeesGroup?.organizationName,
                                  requestLinkStart: '<a id="invitation-request-tab" style="cursor: pointer;">',
                                  linkEnd: "</a>"
                                }
                              })
                            }}
                          />
                        }
                      </>
                      : null
                  }
                </>
                : modalTab === "request" ?
                  <p
                    className="text-base text-black"
                    dangerouslySetInnerHTML={{
                      __html: getTranslationWithParameters({
                        key: "account.organization.invitationsModal.joinAsExternalDescription",
                        parameters: {
                          organizationName: currentInvitation?.organization.name,
                        }
                      })
                    }}
                  />
                  : null
            }

          </div>
        }
        isVisible={isModalVisible}
        closeModal={() => closeModal()}
        buttons={
          modalTab === "invitation" ?
            [
              <CustomButton
                testdataId="organization-invitations-modal-cancel-button"
                key="cancel"
                label={getTranslation("account.organization.invitationsModal.buttons.cancel")}
                type="secondary"
                onClick={() => closeModal()}
              />,
              <CustomButton
                testdataId="organization-invitations-modal-reject-button"
                key="reject"
                label={getTranslation("account.organization.invitationsModal.buttons.reject")}
                type="secondary"
                onClick={rejectInvitation}
              />,
              <CustomButton
                testdataId="organization-invitations-modal-accept-button"
                key="accept"
                label={getTranslation("account.organization.invitationsModal.buttons.accept")}
                type="primary"
                onClick={acceptInvitation}
              />
            ]
            : modalTab === "request" ?
              [
                <CustomButton
                  testdataId="organization-invitations-modal-cancel-button"
                  key="cancel"
                  label={getTranslation("account.organization.invitationsModal.buttons.cancel")}
                  type="secondary"
                  onClick={() => closeModal()}
                />,
                <CustomButton
                  testdataId="organization-invitations-modal-send-button"
                  key="accept"
                  label={getTranslation("account.organization.invitationsModal.buttons.sendRequest")}
                  type="primary"
                  onClick={requestToJoin}
                />
              ]
              : []
        }
      />
      <div className='font-extrabold text-gray-600'>
        {getTranslation("account.organization.pendingInvitations")}
      </div>
      {
        visibleInvitations?.length !== undefined && visibleInvitations?.length > 0 ?
          <table className='table-auto border border-gray-600'>
            <thead className=''>
              <tr>
                <th className='border border-slate-400 p-2 bg-[#f1f1f6] font-normal text-left pl-4 text-gray-600'>{getTranslation("account.organization.invitationsTable.columns.organization")}</th>
                <th className='border border-slate-400 p-2 bg-[#f1f1f6] font-normal text-left pl-4 text-gray-600'>{getTranslation("account.organization.invitationsTable.columns.role")}</th>
                <th className='border border-slate-400 p-2 bg-[#f1f1f6] font-normal text-left pl-4 text-gray-600'>{getTranslation("account.organization.invitationsTable.columns.expires")}</th>
                <th className='border border-slate-400 p-2 bg-[#f1f1f6] font-normal text-left pl-4 text-gray-600'></th>
              </tr>
            </thead>
            <tbody>
              {visibleInvitations?.map((obj, idx) => {
                let rolesOfInvitation = loadRolesColumn(obj);
                return (
                  <tr key={'row-' + idx}>
                    <td className='border border-slate-400 p-2 font-normal text-left pl-4 text-gray-600'>{obj?.organization?.name}</td>
                    <td className='border border-slate-400 p-2 font-normal text-left pl-4 text-gray-600'>{rolesOfInvitation}</td>
                    <td className='border border-slate-400 p-2 font-normal text-left pl-4 text-gray-600'>{obj?.expiryDate ? moment(obj.expiryDate).format('D.M.YYYY') : ''}</td>
                    {
                      obj?.accepted ?
                        <td className='border border-slate-400 p-2 font-normal text-left pl-4'>{getTranslation("account.organization.invitationsTable.status.accepted")}</td>
                        :
                        obj?.rejected ?
                          <td className='border border-slate-400 p-2 font-normal text-left pl-4'>{getTranslation("account.organization.invitationsTable.status.rejected")}</td>
                          :
                          obj?.cancelled ?
                            <td className='border border-slate-400 p-2 font-normal text-left pl-4'>{getTranslation("account.organization.invitationsTable.status.cancelled")}</td>
                            :
                            obj?.expiryDate && moment(obj.expiryDate).isBefore(moment.now()) ?
                              <td className='border border-slate-400 p-2 font-normal text-left pl-4'>{getTranslation("account.organization.invitationsTable.status.expired")}</td>
                              :
                              <td
                                testdata-id={`organization-invitations-open-button-${obj.id}`}
                                className='border border-slate-400 p-2 font-normal text-left pl-4 text-trimble-blue hover:text-trimble-orange cursor-pointer'
                                onClick={() => openInvitation(obj, rolesOfInvitation)}
                              >
                                {getTranslation("account.organization.invitationsTable.buttons.openInvitation")}
                              </td>
                    }
                  </tr>
                )
              })}
            </tbody>
          </table>
          :
          <div>{getTranslation("account.organization.noPendingInvitations")}</div>

      }
    </div>
  )
}

export default OrganizationColumnInvitations;