import { ChangeEvent, useEffect, useState } from 'react';
import { useNotification } from '../../../../providers/NotificationProvider';
import { Organization } from '../../../../../model/Organization';
import CustomSelect from '../../../../custom/CustomSelect';
import CustomButton from '../../../../custom/CustomButton';
import { cancelMembershipRequest, sendMembershipRequest } from '../../../../../assets/api';
import { useNavigate } from 'react-router-dom';
import { MembershipRequest } from '../../../../../model/MembershipRequest';
import { useLocalization } from '../../../../providers/LocalizationProvider';

export function OrganizationColumnNoOrganization(
  {
    suggestedOrganizations,
    membershipRequests
  } : {
    suggestedOrganizations: Array<Organization>,
    membershipRequests: Array<MembershipRequest>
  }) {

  const navigate = useNavigate();
  const { getTranslation } = useLocalization();
  const { sendNotification } = useNotification();
  const [ selectedOrganization, setSelectedOrganization ] = useState<Organization | null>(null);
  const [ pendingRequest, setPendingRequest ] = useState<MembershipRequest | null>(null);

  const handleChangeOrgToRequest = async (e: ChangeEvent<HTMLSelectElement> | ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target as any;
    setSelectedOrganization(suggestedOrganizations[value]);
  }

  const sendRequest = async (e: React.FormEvent<HTMLButtonElement>) => {
    //
    e.preventDefault();
    try {
        if (selectedOrganization != null) {
          //
          let response = await sendMembershipRequest(selectedOrganization.id);
          if (response.status === 200) {
            sendNotification(getTranslation("account.organization.requestMembershipModal.notifications.sendSuccess"), "success");
            navigate('.', { replace: true })
          } else {
            let errorDescription = (await response.json() as any)?.error_description;
            sendNotification(errorDescription ? errorDescription : getTranslation("account.organization.requestMembershipModal.notifications.sendFailed"), "error");
          }
        }
    } catch (err) {
      sendNotification(getTranslation("account.organization.requestMembershipModal.notifications.sendFailed"), "error");
    }
  }

  const cancelRequest = async (e: React.FormEvent<HTMLButtonElement>) => {
    //
    e.preventDefault();
    try {
        if (pendingRequest != null) {
          //
          let response = await cancelMembershipRequest(pendingRequest.organization.id, pendingRequest.id);
          if (response.status === 200) {
            sendNotification(getTranslation("account.organization.requestMembershipModal.notifications.cancelSuccess"), "success");
            navigate('.', { replace: true })
            setPendingRequest(null)
          } else {
            let errorDescription = (await response.json() as any)?.error_description;
            sendNotification(errorDescription ? errorDescription : getTranslation("account.organization.requestMembershipModal.notifications.cancelFailed"), "error");
          }
        }
    } catch (err) {
      sendNotification(getTranslation("account.organization.requestMembershipModal.notifications.cancelFailed"), "error");
    }
  }

  useEffect(() => {
    //
    const foundRequest = membershipRequests?.find(obj => obj.pending);
    if (foundRequest) {
      //
      setPendingRequest(foundRequest)
    } else {
      //
      setPendingRequest(null);
      setSelectedOrganization(null);
    };
  }, [membershipRequests])

  return (
    <div className='space-y-4'>
      <h2 className='text-xl text-gray-900 font-bold'>{getTranslation("account.organization.notMemberTitle")}</h2>
      <div>
        <p className='text-gray-600'>{getTranslation("account.organization.notMemberDescription_1")}</p>
        <p className='text-gray-600'>{getTranslation("account.organization.notMemberDescription_2")}</p>
      </div>
      <div className='text-xl text-gray-900 font-bold'>{getTranslation("account.organization.requestToJoinTitle")}</div>
      {
        suggestedOrganizations != null && suggestedOrganizations.length > 0 ?
        <div className='flex flex-row w-full space-x-4'>
          {
            pendingRequest === null ?
            <>
              <div className='w-64'>
                <CustomSelect
                  label=""
                  optionsLabel={getTranslation("account.organization.requestMembershipDropdown.options.label")}
                  options={suggestedOrganizations}
                  optionsKey="name"
                  setValue={handleChangeOrgToRequest}
                  value={suggestedOrganizations.findIndex(obj => selectedOrganization?.id === obj.id)?.toString()}
                  useIndexAsValue={true}
                />
              </div>
              <CustomButton
                  label={getTranslation("account.organization.buttons.sendRequest")}
                type="primary"
                onClick={sendRequest}
                disabled={!selectedOrganization}
              />
            </> 
          :
            <div className="space-y-6">
              <p>{getTranslation("account.organization.pendingRequest")}</p>
              <div className="flex flex-row space-x-4 items-center justify-center">
                <span className="font-bold">{pendingRequest?.organization?.name}</span>
                <CustomButton
                  label={getTranslation("account.organization.buttons.cancel")}
                  type="secondary"
                  onClick={cancelRequest}
                />
              </div>
            </div>
          }
        </div>
        :
        <div>
          <p className='text-gray-600'>
            {getTranslation("account.organization.noOrganizationsToRequest_1")}
          </p>
          <p className='text-gray-600'>
            {getTranslation("account.organization.noOrganizationsToRequest_2")}
          </p>
        </div>
      }
    </div>
  )
}

export default OrganizationColumnNoOrganization;