
export function CustomButton(
    {
        id,
        testdataId,
        label,
        disabled,
        type,
        customClass,
        onClick
    }:
    {
        id?: string,
        testdataId?: string,
        label?: string,
        disabled?: boolean | undefined,
        type?: "primary" | "secondary" | undefined,
        customClass?: string,
        onClick?: React.MouseEventHandler<HTMLButtonElement>
    }) {

    return (
        <button
            id={id}
            testdata-id={testdataId}
            className={
                `${
                    type === "primary" ? "bg-trimble-blue text-white px-4 py-1 rounded-sm" + (disabled ? " opacity-30" : " hover:bg-trimble-blue-hover") :
                    type === "secondary" ? "bg-white text-trimble-blue px-4 py-1 rounded-sm border-trimble-blue border-[1px]" + (disabled ? " opacity-30" : " hover:bg-blue-100") :
                    ""
                } ${customClass}`
            }
            disabled={disabled}
            onClick={onClick}
        >
            {label}
        </button>
    )
}

export default CustomButton;