import { ChangeEventHandler } from "react";
import { useLocalization } from "../../providers/LocalizationProvider";
import { ObjectValidationError } from "../../../model/ObjectValidationError";


export function CustomInput(
    {
        id,
        testdataId,
        name, 
        label, 
        icon, 
        width, 
        value, 
        setValue, 
        disabled, 
        required, 
        error 
    } : {
        id?: string,
        testdataId?: string,
        name: string, 
        label: string, 
        icon?: any, 
        width?: string, 
        value?: any, 
        setValue: ChangeEventHandler<HTMLInputElement>, 
        disabled?: boolean, 
        required?: boolean, 
        error?:  ObjectValidationError
    }) {

    const { getTranslation } = useLocalization();

    return (
        <div
            className={`flex flex-col text-xs text-[#464b52] text w-[${width ? width : '320px'}]`}
        >
            <label
                id="dropdown-header"
                className="flex flex-row justify-between pb-2"
            >
                <div>{label}: {required ? "*" : ""}</div>
                <div>
                    {icon ? 
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className="mi-solid mi-help fill-trimble-blue cursor-pointer" viewBox="0 0 24 24">
                        {icon}
                    </svg>
                    : null}
                </div>
            </label>
            <input
                id={id}
                testdata-id={testdataId}
                required={required ? required : false}
                disabled={disabled != null ? disabled : false}
                name={name}
                value={value}
                onChange={setValue}
                className={`
                    bg-white ${ error ? "border-red-400 border-b-red-600" : "border-gray-200 border-b-black"}
                    focus:border-b-trimble-blue focus:border-gray-200
                    disabled:bg-gray-100
                    w-[${width ? width : '320px'}] flex flex-row justify-between cursor-text outline-none border-[1px] py-2 pl-4`}
            >
            </input>
            {
                error ? 
                <div className="my-2 text-red-600">{error.error_description}</div>
                : null
            }
        </div>
    )
}

export default CustomInput;