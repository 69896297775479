import AccountLayout from "../components/layouts/account";
import MyProfile from "../components/pages/myProfile";
import MyOrganization from "../components/pages/myOrganization";
import MyLicenses from "../components/pages/myLicenses";
import ActivateLicenses from "../components/pages/activateLicenses";
import ErrorPage from "../components/pages/error";
import LandingPage from "../components/pages/landing";
import MainLayout from "../components/layouts/main";
import {
    getAvailableLicenses,
    getAvailableTrials,
    getMembershipRequests,
    getOnlineProfile,
    getOrganizationGroups,
    getOrganizationName,
    getOrganizationRoles,
    getPendingInvitations,
    getSuggestedOrganizations
} from "../assets/api";
import { OrganizationGroup } from "../model/OrganizationGroup";
import RequestMembershipPage from "../components/pages/request";
import {
    PARAMETER_NAME_AUTHZ_HANDLER,
    PARAMETER_NAME_CONTINUE_TO,
    PARAMETER_NAME_ENCODED_PARAMETERS,
    PARAMETER_NAME_INVITATION_CODE,
    PARAMETER_NAME_REDIRECT_URI,
    PARAMETER_NAME_REQUEST_TO_JOIN,
    PARAMETER_NAME_RETURN_TO
} from "../assets/constants";
import { redirect } from "react-router-dom";
import { User } from "../model/User";

const parseJsonOrReturnNull = async (response: Response | null) => {
    //
    return response?.status === 200 && response?.headers?.get('content-type') === "application/json" ? await response.json() : null;
}

/**
 * Decodes encodedParams and returns parameters based on the result.
 * If encodedParams contains multiple parts that are split by
 * underscores, it will create a redirection url based on the first
 * part and returns decoded parameters with it.
 */
const decodeAndResolveEncodedParams = (searchParams: URLSearchParams) => {
    //
    const searchParam = searchParams.get(PARAMETER_NAME_ENCODED_PARAMETERS);
    if (searchParam) {
        //
        searchParams.delete(PARAMETER_NAME_ENCODED_PARAMETERS)
        try {
            //
            const decodedParams = atob(searchParam.replace(/_/g, '/').replace(/-/g, '+'));
            if (decodedParams.startsWith("/oauth2/")) {
                //
                searchParams.append(PARAMETER_NAME_REDIRECT_URI, decodedParams)
            } else {
                //
                const decodedSearchParams = new URLSearchParams(decodedParams);
                let redirectParameter = decodedSearchParams.get(PARAMETER_NAME_REDIRECT_URI);
                let authzHandlerParameter = decodedSearchParams.get(PARAMETER_NAME_AUTHZ_HANDLER);
                if (redirectParameter || (authzHandlerParameter && decodeURIComponent(authzHandlerParameter) === "/oauth")) {
                    //
                    searchParams.append(PARAMETER_NAME_REDIRECT_URI, `/login/index.html?${PARAMETER_NAME_ENCODED_PARAMETERS}=${searchParam}`);
                } else {
                    //
                    let continueToParameter = decodedSearchParams.get(PARAMETER_NAME_CONTINUE_TO);
                    if (continueToParameter) {
                        //
                        searchParams.append(PARAMETER_NAME_CONTINUE_TO, continueToParameter);
                    }
                    let invitationCodeParameter = decodedSearchParams.get(PARAMETER_NAME_INVITATION_CODE);
                    if (invitationCodeParameter) {
                        //
                        searchParams.append(PARAMETER_NAME_INVITATION_CODE, invitationCodeParameter);
                    }
                }
            }
        } catch (err) {
            // ignore silently if parsin fails
        }
        return searchParams;
    } else {
        //
        return searchParams;
    }
}

const resolvePathForUnauthenticatedUser = ({
    path,
    params
}: {
    path: string,
    params: URLSearchParams
}): string => {
    //
    let protectedRoute = accountRoutes.find((route) => route.url === path);
    let continueToParameters = params.getAll(PARAMETER_NAME_CONTINUE_TO);
    if (continueToParameters?.length > 0) {
        //
        for (const continueToParameter of continueToParameters) {
            //
            if (continueToParameter === "/login/index.html") {
                //
                params.delete(PARAMETER_NAME_CONTINUE_TO);
                continueToParameters = continueToParameters.filter(i => i !== continueToParameter);
                continueToParameters.forEach(i => params.append(PARAMETER_NAME_CONTINUE_TO, i));
            }
        }
    }
    if (protectedRoute || path === "/login/index.html" || path === "/logout/index.html") {
        //
        params.append(PARAMETER_NAME_CONTINUE_TO, path);
        return params.size > 0 ? `/?${params.toString()}` : "/";
    }
    //
    return params.size > 0 ? `${path}?${params.toString()}` : path;
}

const resolvePathForAuthenticatedUser = ({
    user,
    path,
    params
}: {
    user: User,
    path: string,
    params: URLSearchParams
}): string => {
    //
    let redirectPath;
    let protectedRoute = accountRoutes.find((route) => route.url === path);
    //
    try {
        //
        const referrer = window.document.referrer;
        if (referrer) {
            //
            const referrerUrl = new URL(referrer);
            let returnUrl = null;
            const returnParam = params.get(PARAMETER_NAME_RETURN_TO);
            if (returnParam) {
                returnUrl = new URL(returnParam);
            }
            //
            const whitelistedReferrers = new RegExp(window.ENV.RETURN_WHITELIST_PATTERN);
            const blacklistedReferrers = new RegExp(window.ENV.RETURN_BLACKLIST_PATTERN);
            if (
                window.location.hostname !== referrerUrl.hostname &&
                referrerUrl.hostname !== returnUrl?.hostname &&
                whitelistedReferrers.test(referrerUrl.toString()) &&
                !blacklistedReferrers.test(referrerUrl.toString())
            ) {
                //
                params.append(PARAMETER_NAME_RETURN_TO, referrer);
            }
        }
    } catch (err) {
        // ignore silently if referrer is not supported url
    }
    //
    let continueToParameters = params.getAll(PARAMETER_NAME_CONTINUE_TO);
    if (continueToParameters?.length > 0) {
        //
        for (const continueToParameter of continueToParameters) {
            //
            if (decodeURIComponent(continueToParameter) === "/login/index.html") {
                //
                params.delete(PARAMETER_NAME_CONTINUE_TO);
                continueToParameters = continueToParameters.filter(i => i !== continueToParameter);
                continueToParameters.forEach(i => params.append(PARAMETER_NAME_CONTINUE_TO, i));
            }
        }
    }
    //
    const redirectParameter = params.get(PARAMETER_NAME_REDIRECT_URI);
    const oauthContinueParam = continueToParameters?.filter((param) => param.startsWith("/oauth2/"));
    if (user.isProfileCompleted) {
        //
        if (redirectParameter && oauthContinueParam.length === 0) {
            //
            return redirectParameter;
        }
        if (continueToParameters?.length > 0) {
            //
            for (const continueToParameter of continueToParameters) {
                //
                const internal = !continueToParameter.match(/^http|^https|^www/);
                if (internal && !continueToParameter.startsWith("/oauth2/") && !continueToParameter.startsWith("/login/index.html")) {
                    //
                    params.delete(PARAMETER_NAME_CONTINUE_TO);
                    continueToParameters = continueToParameters.filter(i => i !== continueToParameter);
                    continueToParameters.forEach(i => params.append(PARAMETER_NAME_CONTINUE_TO, i));
                    return params.size > 0 ? `${continueToParameter}?${params.toString()}` : continueToParameter;
                }
            }
        }
    } else {
        //
        if (redirectParameter && oauthContinueParam.length === 0) {
            params.delete(PARAMETER_NAME_REDIRECT_URI);
            params.append(PARAMETER_NAME_CONTINUE_TO, redirectParameter);
        } else if (path !== "/account/") {
            //
            params.append(PARAMETER_NAME_CONTINUE_TO, path);
        }
        return params.size > 0 ? `/account/?${params.toString()}` : "/account/";
    }
    if (!protectedRoute && !path.includes("/membership-request")) {
        //
        return params.size > 0 ? `/account/?${params.toString()}` : "/account/";
    }
    //
    if (!redirectPath) {
        return params.size > 0 ? `${path}?${params.toString()}` : path;
    }
    return redirectPath;
}

const resolveParameters = ({
    searchParams
}: {
    searchParams: URLSearchParams
}): URLSearchParams => {
    //
    return decodeAndResolveEncodedParams(searchParams);
}

/**
 * Loads user profile and does base redirections depending on authentication and continueTo-parameter.
 */
const rootLoader = async ({ request }: { request: Request }) => {
    //
    // get user
    const userResponse = await getOnlineProfile();
    // remove continueTo login index from parameters as it is always included on upstream login
    const currentUrl = new URL(request.url);
    let params;
    const decodedParams = decodeURIComponent(currentUrl.searchParams.toString());
    if (decodedParams.startsWith("continueTo=/login/index.html")) {
        params = decodedParams.replace("continueTo=/login/index.html", "");
    } else {
        params = currentUrl.search;
    }
    const replacedParameters = new URLSearchParams(
        params
    );
    // reconstruct parameters and do modifications if needed on them
    const targetParameters: URLSearchParams = resolveParameters({
        searchParams: replacedParameters
    })
    // save current path for comparison with the result path
    let entryPath = currentUrl.searchParams.size > 0
        ? `${currentUrl.pathname}?${currentUrl.searchParams.toString()}`
        : currentUrl.pathname;
    // resolve path to follow depending on user profile
    let redirectToPath;
    let userJson;
    if (!userResponse.ok) {
        //
        redirectToPath = resolvePathForUnauthenticatedUser({
            path: currentUrl.pathname,
            params: targetParameters
        })
    } else {
        userJson = await parseJsonOrReturnNull(userResponse);
        redirectToPath = resolvePathForAuthenticatedUser({
            user: userJson,
            path: currentUrl.pathname,
            params: targetParameters
        })
    }
    // redirect if paths don't match
    const decodedRedirectToPath = decodeURIComponent(redirectToPath);
    const decodedEntryPath = decodeURIComponent(entryPath);
    if (decodedEntryPath !== decodedRedirectToPath) {
        //
        if (decodedRedirectToPath.startsWith("/login/index.html") || decodedRedirectToPath.startsWith("/oauth2/")) {
            //
            window.location.href = redirectToPath;
        } else if (decodedRedirectToPath.startsWith("//webadmin")) {
            //
            window.location.href = `${currentUrl.origin}/webadmin`
        } else {
            //
            return redirect(redirectToPath);
        }
    }
    // no need to redirect, load remaining data
    const [groups, roles] = userResponse.status === 200 ? await Promise.all([
        getOrganizationGroups(),
        getOrganizationRoles(),
    ]) : [null, null];
    let groupsJson = groups?.status === 200 ? await groups.json() : null;
    let employeesGroupJson = null;
    if (groupsJson) {
        if (Array.isArray(groupsJson)) {
            for (let group of groupsJson) {
                if ('employees' === (group as OrganizationGroup).type?.toLocaleLowerCase()) {
                    employeesGroupJson = group;
                    break;
                }
            }
        }
    }
    return {
        user: userJson,
        employeesGroup: employeesGroupJson,
        groups: groupsJson,
        roles: await parseJsonOrReturnNull(roles),
    };
}

export const accountRoutes = [
    {
        id: "my-profile",
        index: true,
        element: <MyProfile />,
        loader: async () => {
            let groups = await getOrganizationGroups();
            let groupsJson = await parseJsonOrReturnNull(groups);
            let employeesGroup = null;
            if (Array.isArray(groupsJson)) {
                for (let group of groupsJson) {
                    if ('employees' === (group as OrganizationGroup).type?.toLocaleLowerCase()) {
                        employeesGroup = group;
                        break;
                    }
                }
            }
            return employeesGroup;
        },
        // Following are used by navigation
        label: 'navigation.side.profile',
        url: '/account/',
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="mi-solid mi-home" viewBox="0 0 24 24">
                <path d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1Z" />
            </svg>
        )
    },
    {
        id: "my-organization",
        path: "myOrganization.html",
        element: <MyOrganization />,
        loader: async ({ request }: { request: any }) => {
            let requestUrl = new URL(request.url);
            const invitationCode = requestUrl.searchParams.get(PARAMETER_NAME_INVITATION_CODE);
            const organizationId = requestUrl.searchParams.get(PARAMETER_NAME_REQUEST_TO_JOIN);
            const [requests, suggestedOrganizations, pendingInvitations, organizationToRequest] = await Promise.all([
                getMembershipRequests(),
                getSuggestedOrganizations(),
                getPendingInvitations(invitationCode),
                organizationId ? getOrganizationName(organizationId) : null
            ]);
            return {
                requests: await parseJsonOrReturnNull(requests),
                suggestedOrganizations: await parseJsonOrReturnNull(suggestedOrganizations),
                pendingInvitations: await parseJsonOrReturnNull(pendingInvitations),
                organizationToRequest: await parseJsonOrReturnNull(organizationToRequest)
            };
        },
        // Following are used by navigation
        label: 'navigation.side.organizationAndRoles',
        url: '/account/myOrganization.html',
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="mi-solid mi-buildings" viewBox="0 0 24 24">
                <path d="M21.5 3h-2V2h-6v1h-2c-.55 0-1 .45-1 1v17h-1V11H4.79c-.55 0-1 .45-1 1v1H2.5c-.55 0-1 .45-1 1v8h14v-3.5h2V22h5V4c0-.55-.45-1-1-1ZM5 20.5H3v-2h2v2Zm0-3H3v-2h2v2Zm3 3H6v-2h2v2Zm0-3H6v-2h2v2Zm0-3H6v-2h2v2Zm6.5 1h-2v-2h2v2Zm0-3h-2v-2h2v2Zm0-3h-2v-2h2v2Zm0-3h-2v-2h2v2Zm3 9h-2v-2h2v2Zm0-3h-2v-2h2v2Zm0-3h-2v-2h2v2Zm0-3h-2v-2h2v2Zm3 9h-2v-2h2v2Zm0-3h-2v-2h2v2Zm0-3h-2v-2h2v2Zm0-3h-2v-2h2v2Z" />
            </svg>
        )
    },
    {
        id: "my-licenses",
        path: "myLicenses.html",
        element: <MyLicenses />,
        loader: async () => {
            const [licenses] = await Promise.all([
                getAvailableLicenses()
            ]);
            return {
                licenses: await parseJsonOrReturnNull(licenses)
            };
        },
        // Following are used by navigation
        label: 'navigation.side.licenses',
        url: '/account/myLicenses.html',
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="mi-solid mi-clipboard-planning" viewBox="0 0 24 24">
                <path d="M8 11h1v1H8v-1Zm0 4h1v-1H8v1Zm0 3h1v-1H8v1Zm3-6h5v-1h-5v1Zm0 3h5v-1h-5v1Zm0 3h5v-1h-5v1Zm9-11v13c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V7c0-1.1.9-2 2-2h3c0-1.66 1.34-3 3-3s3 1.34 3 3h3c1.1 0 2 .9 2 2Zm-2 0h-2v1H8V7H6v13h12V7Z" />
            </svg>
        )
    },
    {
        id: "activate-licenses",
        path: "activateLicenses.html",
        element: <ActivateLicenses />,
        loader: async () => {
            const [trials] = await Promise.all([
                getAvailableTrials()
            ]);
            return {
                licenses: await parseJsonOrReturnNull(trials)
            };
        },
        // Following are used by navigation
        label: 'navigation.side.activateLicenses',
        url: '/account/activateLicenses.html',
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="mi-solid mi-list-bulleted" viewBox="0 0 24 24">
                <path d="M5.5 10.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5S7 12.83 7 12c.01-.82-.64-1.49-1.46-1.5H5.5Zm0-5C4.67 5.5 4 6.17 4 7s.67 1.5 1.5 1.5S7 7.83 7 7c.01-.82-.64-1.49-1.46-1.5H5.5Zm0 10c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5S7 17.83 7 17c.01-.82-.64-1.49-1.46-1.5H5.5ZM9 18h10c.55 0 1-.45 1-1s-.45-1-1-1H9c-.55 0-1 .45-1 1s.45 1 1 1Zm0-5h10c.55 0 1-.45 1-1s-.45-1-1-1H9c-.55 0-1 .45-1 1s.45 1 1 1ZM8 7c0 .55.45 1 1 1h10c.55 0 1-.45 1-1s-.45-1-1-1H9c-.55 0-1 .45-1 1Z" />
            </svg>
        ),
    },
]

export const routes = [
    {
        id: 'root',
        path: "/",
        element: <MainLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                id: "landing",
                index: true,
                element: <LandingPage />,
            },
            {
                path: "/account/",
                element: <AccountLayout />,
                children: accountRoutes,
            },
            {
                id: "membership-request",
                path: "/membership-request/",
                element: <RequestMembershipPage />,
                loader: async ({ request }: { request: any }) => {
                    const organizationId = new URL(request.url).searchParams.get(PARAMETER_NAME_REQUEST_TO_JOIN);
                    if (organizationId !== null) {
                        const [organization] = await Promise.all([
                            getOrganizationName(organizationId)
                        ]);
                        return await parseJsonOrReturnNull(organization)
                    } else {
                        throw new Error("Organization identifier is missing")
                    }
                },
            },
            /**
             * Following are to prevent in-application redirection to login/logout endpoints.
            {
                path: "/login/*",
                element: <Navigate to={{pathname: "/", search: window.location.search}} replace />
            },
            {
                path: "/logout/*",
                element: <Navigate to={{pathname: "/", search: window.location.search}} replace />
            }
             */
        ],
        loader: rootLoader
    },
];