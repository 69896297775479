import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import CustomButton from '../../custom/CustomButton';
import { useLocalization } from '../../providers/LocalizationProvider';
import { PARAMETER_NAME_LOGIN_PATH } from '../../../assets/constants';

export function LandingPage() {

  const [ URLSearchParams ] = useSearchParams();
  const { getTranslation } = useLocalization();

  const signIn = async () => {
    //
    let loginPath = `${PARAMETER_NAME_LOGIN_PATH}&${URLSearchParams.toString()}`;
    window.location.href = loginPath;
  }

  return (
    <div
      id='landing-page'
      className='flex justify-center w-full px-20 lg:px-40 xl:px-40 2xl:px-80'
    >
      <div
        id='landing-col'
        className='flex flex-col gap-8 mt-24 h-full w-2/3 text-gray-text text-center items-center mb-24'
      >
        <h1 className='text-4xl font-semibold'>{getTranslation("landing.title")}</h1>
        <p>{getTranslation("landing.description")}</p>
        <CustomButton
          testdataId="landing-signing-button"
          label={getTranslation("landing.signInButton")}
          type='primary'
          customClass='w-72 py-2'
          onClick={() => signIn()}
        />
      </div>
    </div>
  )
}

export default LandingPage;