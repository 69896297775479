import { ChangeEventHandler } from "react";
import { useLocalization } from "../../providers/LocalizationProvider";
import { ObjectValidationError } from "../../../model/ObjectValidationError";


export function CustomSelect(
    {
        id,
        testdataId,
        label,
        name,
        options,
        optionsLabel,
        optionsKey,
        icon,
        width,
        value,
        setValue,
        translationPrefix,
        required,
        error,
        useIndexAsValue
    } : {
        id?: string,
        testdataId?: string,
        label: string,
        name?: string,
        options?: Array<any>,
        optionsLabel?: string,
        optionsKey?: string,
        icon?: any,
        width?: string,
        value?: any,
        setValue: ChangeEventHandler<HTMLSelectElement>,
        translationPrefix?: string,
        required?: boolean,
        error?: ObjectValidationError | null,
        useIndexAsValue?: boolean
    }) {

    const { getTranslation } = useLocalization()

    const resolveTranslation = (toTranslate: string) => {
        let translationPrefixToUse = translationPrefix ? translationPrefix : "";
        return getTranslation(translationPrefixToUse + toTranslate);
    }

    return (
        <div
            className={`flex flex-col text-xs text-[#464b52] text ${width ? width : 'w-full'}`}
        >
            <label
                id="dropdown-header"
                className="flex flex-row justify-between pb-2"
            >
                {label ? <div>{label}: {required ? "*" : ""}</div> : null}
                {
                    icon ?
                    icon
                    : null
                }
            </label>
            <select
                id={id}
                testdata-id={testdataId}
                name={name}
                value={value ? value : optionsLabel}
                required={required ? required : false}
                onChange={setValue}
                className={`
                    bg-white ${error ? 'border-red-400 border-b-red-600' : 'border-gray-200 border-b-black'}
                    focus:border-b-trimble-blue focus:border-gray-200
                    border-[1px] py-2 pl-4 ${width ? width : 'w-full'} flex flex-row justify-between cursor-pointer border-b-[1px] outline-none
                `}
            >
                <option hidden>{optionsLabel}</option>
                {options?.map((obj, idx) => {
                    return (
                        <option
                            key={'obj-' + idx}
                            className={`hover:bg-blue-500 hover:text-white pl-4`}
                            value={
                                useIndexAsValue ? idx : obj
                            }
                        >
                            {optionsKey ? resolveTranslation(obj[optionsKey]) : resolveTranslation(obj)}
                        </option>
                )})}
            </select>
            {
                error ? 
                <div className="my-2 text-red-600">{error.error_description}</div>
                : null
            }
        </div>
    )
}

export default CustomSelect;