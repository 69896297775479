import { ModusAlert } from "@trimble-oss/modus-react-components";
import { createContext, useContext, useState } from "react";

interface ContextProps {
    readonly sendNotification: (title: string, type?: 'success' | 'warning' | 'info' | 'error') => void;
}

const NotificationContext = createContext<ContextProps>({
    sendNotification: () => {}
});

export const useNotification = () => {
	return useContext(NotificationContext)
}

export const NotificationProvider = ({ children }: { children: React.ReactNode }) => {

    const [ title, setTitle ] = useState<string>("Success");
    const [ type, setType ] = useState<'success' | 'warning' | 'info' | 'error'>("success");
    const [ visible, setVisible ] = useState<boolean>(false);

    const sendNotification = async (
        title: string,
        type?: 'success' | 'warning' | 'info' | 'error'
    ) => {
        setTitle(title);
        setType(type ? type : 'success')
        setVisible(true);
        await new Promise(r => setTimeout(r, 5000));
        setVisible(false);
    }

    const value = {
        sendNotification
    }

    return (
        <NotificationContext.Provider value={value}>
            {visible ?
                <ModusAlert
                    className='absolute mt-20 right-5 z-10'
                    message={title}
                    type={type}
                />
                : null
            }
            {children}
        </NotificationContext.Provider>
    );
};