const getRequest = async (path: string) => {
    return await fetch(path, {
        method: 'GET',
        redirect: 'follow',
        credentials: "include",
    }).then(async res => {
        return res;
    }).catch(err => {
        throw new Response(err, { status: 500, statusText: err.statusText });
    });
}

const postRequest = async (path: string, json?: string) => {
    return await fetch(path, {
        method: 'POST',
        redirect: 'follow',
        credentials: "include",
        body: json,
        headers: {
            "Content-Type": "application/json"
        }
    }).then(async res => {
        return res;
    }).catch(err => {
        throw new Response(err, { status: 500, statusText: err.statusText });
    });
}

const putRequest = async (path: string, json?: string) => {
    return await fetch(path, {
        method: 'PUT',
        redirect: 'follow',
        credentials: "include",
        body: json,
        headers: {
            "Content-Type": "application/json"
        }
    }).then(async res => {
        return res;
    }).catch(err => {
        throw new Response(err, { status: 500, statusText: err.statusText });
    });
}

export const getUser = async () => {
    return await getRequest("/api/account/v1/users/me");
}

export const updateUser = async (json: string) => {
    return await putRequest("/api/account/v1/users/me", json);
}

export const getOnlineProfile = async () => {
    return await getRequest("/api/account/v1/users/me/online-profile");
}

export const updateOnlineProfile = async (json: string) => {
    return await putRequest("/api/account/v1/users/online-profile", json);
}

export const getOrganizationGroups = async () => {
    return await getRequest("/api/account/v1/users/me/organization-groups");
}

export const getOrganizationRoles = async () => {
    return await getRequest("/api/account/v1/users/me/organization-roles");
}

export const getMembershipRequests = async () => {
    return await getRequest("/api/account/v1/users/me/membership-requests");
}

export const getSuggestedOrganizations = async () => {
    return await getRequest("/api/account/v1/users/me/suggested-organizations");
}

export const getPendingInvitations = async (invitationCode?: string | null) => {
    return await getRequest(`/api/account/v1/users/me/pending-invitations${invitationCode ? '?invitationCode=' + invitationCode : ''}`);
}

export const getOrganizationAdmins = async (organizationId: string) => {
    return await getRequest(`/api/account/v1/organizations/${organizationId}/organization-roles/Admin/users`);
}

export const getAvailableLicenses = async () => {
    return await getRequest("/api/account/v1/users/me/available-licenses?checkLicense=true");
}

export const acceptInvitation = async (type: string, code: string) => {
    //
    return await postRequest(`/api/account/v1/invitations/${type}/${code}/accept`);
}

export const rejectInvitation = async (type: string, code: string) => {
    //
    return await postRequest(`/api/account/v1/invitations/${type}/${code}/reject`);
}

export const sendMembershipRequest = async (organizationId: string, groupType?: string) => {
    //
    return await postRequest(`/api/account/v1/organizations/${organizationId}/membership-requests${groupType ? `?groupType=${groupType}` : ""}`);
}

export const cancelMembershipRequest = async (organizationId: string, requestId: string) => {
    //
    return await postRequest(`/api/account/v1/organizations/${organizationId}/membership-requests/${requestId}/cancel`);
}

export const getAvailableTrials = async () => {
    //
    return await getRequest("/api/account/v1/users/me/available-trials");
}

export const startTrial = async (trialId: string) => {
    //
    return await postRequest(`/api/account/v1/users/me/available-trials/${trialId}/start`);
}

export const getOrganizationName = async (organizationId: string) => {
    //
    return await getRequest(`/api/account/v1/organizations/${organizationId}/get-name`);
}

export const synchronizeUserDataWithExternalProvider = async () => {
    //
    return await postRequest("/api/account/v1/users/me/synchronize");
}